import React, { useEffect, useState } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";
import Select from "react-select";
import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Image } from "react-bootstrap";
// import {CranberryJuice} from "../../assets/Ingredients/Cranberry Juice.PNG"
import CranberryJuice from "../../assets/Ingredients/Cranberry Juice.PNG";
import ElderflowerLiqueur from "../../assets/Ingredients/Elderflower Liqueur.PNG";
import GrapefruitJuice from "../../assets/Ingredients/Grapefruit Juice.PNG";
import LemonJuice from "../../assets/Ingredients/Lemon Juice.PNG";
import LimeJuice from "../../assets/Ingredients/Lime Juice.PNG";
import OrangeJuice from "../../assets/Ingredients/Orange Juice.PNG";
import SimpleSyrup from "../../assets/Ingredients/Simple Syrup.PNG";
import SodaWater from "../../assets/Ingredients/Soda Water.PNG";
import Tequila from "../../assets/Ingredients/Tequila.PNG";
import TonicWater from "../../assets/Ingredients/Tonic Water.PNG";
import TripleSec from "../../assets/Ingredients/Triple Sec.PNG";
import Vodka from "../../assets/Ingredients/Vodka.PNG";
import Whiskey from "../../assets/Ingredients/Whiskey.PNG";
import WhiteRum from "../../assets/Ingredients/White Rum.PNG";
// Updated import statements
import HotToddyWithCinnamonTwist from "../../assets/Newrecipes/Hot Toddy with Cinnamon Twist.jpg";
import MulledWine from "../../assets/Newrecipes/Mulled Wine.jpg";
import NutmegInfusedOldFashioned from "../../assets/Newrecipes/Nutmeg Infused Old Fashioned.jpg";
import SpicedAppleCiderSangria from "../../assets/Newrecipes/Spiced Apple Cider Sangria.jpg";
import WinterSpiceMartini from "../../assets/Newrecipes/Winter Spice Martini.jpg";

import Paginationpagewise from "./../../components/pagination/Paginationpagewise.jsx";
import Pagination from "./../../components/pagination/Pagination.jsx";

import {
  dateOptions,
  AgeOptions,
  userOptions,
  RecipeTypeOptions,
  sortOptions,
  locationOptions,
  DeviceOptions
} from "./../../components/selectvalues/selectvalue.jsx";

import { ModalComponent } from "../expand/expandmodal.jsx";

import { Geomapexpand } from "../expand/geomapexpand.jsx";

import glassshimmer from "../../assets/glassshimmer.png";

function Dashboard() {
  const [statsData, setStatsData] = useState();
  // const [serverData, setServerData] = useState();
  const [countryData, setCountryData] = useState();
  // const [sourceData, setSourceData] = useState();
  // const [sourceChartData, setSourceChartData] = useState();
  const [productData, setProductData] = useState();
  // const [activityLogData, setActivityLogData] = useState();
  const [chartOptions, setChartOptions] = useState(getChartOptions());
  // const [serverChartOptions, setServerChartOptions] = useState(
  //   getServerChartOptions()
  // );
  const [GeoMap, setGeoMap] = useState(renderMap());
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [itemsPerPageNewRecipe] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  // console.log(timestampVariable);

  //API Intergration UseState

  const [data, setData] = useState(null); //Dashboard

  const [data2, setData2] = useState(null); //Dashboard

  const [DashUserTile, setDataUserTile] = useState([]); //BarGraph TRUExpand

  const [DashHardwareTile, setDataHardwareTile] = useState([]); //BarGraph HardwareExpand

  const [CraftedTile, setDataCraftedTile] = useState([]); //LineGraph CraftedCocktailExpand

  const [ActiveTile, setDataActiveTile] = useState([]); //LineGraph CraftedCocktailExpand

  const [ReceipeProductTile, setReceipeProductTile] = useState([]); //Top Products Expands
  const [IngredientsProductTile, setIngredientsProductTile] = useState([]); //Top Products Expands
  const [BrandsProductTile, setBrandsProductTile] = useState([]); //Top Products Expands
  const [BarsProductTile, setBarsProductTile] = useState([]); //Top Products Expands
  const [InfluencerProductTile, setInfluencersProductTile] = useState([]); //Top Products Expands
  const [Topproductsortbyoption, settopproductsortby] = useState(
    sortOptions[2]
  );
  const [searchTerm, setSearchTerm] = useState("");











  const [UserReceipeTile, setUserReceipeTile] = useState([]); //Receipes Expand
  const [BarsysReceipeTile, setBarsysRecipeTile] = useState([]);
  const [InfluencerReceipeTile, setInfluencerReceipeTile] = useState([]); //Receipes Expand
  const [BrandReceipeTile, setBrandReceipeTile] = useState([]); //Receipes Expand


  const [MixReceipeTile, setMixReceipeTile] = useState([]); //Mixlist Expands
  const [MixInfluencersTile, setMixInfluencerTile] = useState([]); //Mixlist Expands
  const [MixBrandTile, setMixBrandTile] = useState([]); //Mixlist Expands





  

  // State for pagination
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 10; // Adjust as needed

  // Loading and error states
  // const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);









  // console.log(UserReceipeTile, InfluencerReceipeTile, BrandReceipeTile);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //TRUTile
  const [selectedDateOption, setSelectedDateOption] = useState(dateOptions[1]);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedAgeOption, setSelectedAgeOption] = useState(AgeOptions[0]);

  //CraftedTile
  const [craftedselectedDateOption, setCraftedSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [craftedcustomDateRange, setcraftedCustomDateRange] = useState(false);
  const [craftedstartDate, setcraftedStartDate] = useState(new Date());
  const [craftedendDate, setcraftedEndDate] = useState(new Date());
  const [craftedselectedAgeOption, setcraftedSelectedAgeOption] = useState(
    AgeOptions[0]
  );



    //HardwareTile
    const [hardwareselectedDateOption, setHardwareSelectedDateOption] = useState(
      dateOptions[1]
    );
    const [hardwarecustomDateRange, sethardwareCustomDateRange] = useState(false);
    const [hardwarestartDate, sethardwareStartDate] = useState(new Date());
    const [hardwareendDate, sethardwareEndDate] = useState(new Date());
    const [hardwaredeviceOption, sethardwaredeviceOption] = useState(
      DeviceOptions[0]
    );
    const [hardwarebumberdata, sethardwarenumberdata] = useState(false);



  //ActiveTile
  const [activeselectedDateOption, setActiveSelectedDateOption] = useState(
    dateOptions[1]
  );
  const [activecustomDateRange, setActiveCustomDateRange] = useState(false);
  const [activestartDate, setActiveStartDate] = useState(new Date());
  const [activeendDate, setActiveEndDate] = useState(new Date());
  const [activeselectedAgeOption, setActiveSelectedAgeOption] = useState(
    AgeOptions[0]
  );

  // Get the current date
  var currentDate = new Date();

  // Convert the current date to a timestamp in seconds
  var timestampInSeconds = Math.floor(currentDate.getTime() / 1000);

  // Store the timestamp in a variable

  function getChartOptions() {
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    var themeColorRgb = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme-rgb")
      .trim();

    return {
      bar: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      line: {
        colors: [themeColor],
        chart: { sparkline: { enabled: true } },
        stroke: { curve: "straight", width: 2 },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      pie: {
        colors: [
          "rgba(" + themeColorRgb + ", 1)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .5)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
      donut: {
        colors: [
          "rgba(" + themeColorRgb + ", .15)",
          "rgba(" + themeColorRgb + ", .35)",
          "rgba(" + themeColorRgb + ", .55)",
          "rgba(" + themeColorRgb + ", .75)",
          "rgba(" + themeColorRgb + ", .95)",
        ],
        chart: { sparkline: { enabled: true } },
        stroke: { show: false },
        tooltip: {
          x: { show: false },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
        },
      },
    };
  }

 

  function renderMap() {
    var inverse = getComputedStyle(document.body)
      .getPropertyValue("--bs-inverse")
      .trim();
    var themeColor = getComputedStyle(document.body)
      .getPropertyValue("--bs-theme")
      .trim();
    const map = document.getElementById("world-map");
    const mapElm = document.querySelectorAll(".jvm-tooltip");

    if (map) {
      for (let i = 0; i < mapElm.length; i++) {
        mapElm[i].remove();
      }
      map.innerHTML = "";

      new jsVectorMap({
        selector: "#world-map",
        map: "world",
        zoomButtons: true,
        normalizeFunction: "polynomial",
        hoverOpacity: 0.5,
        hoverColor: false,
        zoomOnScroll: false,
        series: { regions: [{ normalizeFunction: "polynomial" }] },
        labels: { markers: { render: (marker) => marker.name } },
        focusOn: { x: 0.5, y: 0.5, scale: 1 },
        markerStyle: {
          initial: { fill: themeColor, stroke: "none", r: 5 },
          hover: { fill: themeColor },
        },
        regionStyle: {
          initial: {
            fill: inverse,
            fillOpacity: 0.35,
            stroke: "none",
            strokeWidth: 0.4,
            strokeOpacity: 1,
          },
          hover: { fill: themeColor, fillOpacity: 0.9 },
        },
        backgroundColor: "transparent",
      });
    }
  }

  // Top Products section
  const producttabs = [
    { id: "receipes", label: "Recipes", active: true },
    { id: "ingredients", label: "Ingredients", active: false },
    { id: "brands", label: "Brands", active: false },
    // { id: "bars", label: "Bars", active: false },
    { id: "influencers", label: "Influencers", active: false },
  ];
  const producttabsEX = [
    { id: "receipesproex", label: "Recipes", active: true },
    { id: "ingredientsproex", label: "Ingredients", active: false },
    { id: "brandsproex", label: "Brands", active: false },
    // { id: "barsproex", label: "Bars", active: false },
    { id: "influencerprosex", label: "Influencers", active: false },
  ];



  const newreceipestabs = [
    { id: "receipesmain", label: "Users", active: true },
    { id: "barsysrecipemain", label: "Barsys Curated", active: false },
    { id: "ingredientsmain", label: "Influencers", active: false },
    { id: "brandsmain", label: "Brands", active: false },
  ];

  const newreceipestabsEX = [
    { id: "receipesex", label: "Users", active: true },
    { id: "barsysrecipeex", label: "Barsys Curated", active: false },
    { id: "ingredientseex", label: "Influencers", active: false },
    { id: "brandsex", label: "Brands", active: false },
  ];

  const newmixlisttabs = [
    { id: "receipesmix", label: "Recipes", active: true },
    { id: "influencermix", label: "Influencers", active: false },
    { id: "brandsmix", label: "Brands", active: false },
  ];

  const newmixlisttabsEX = [
    { id: "receipesmixex", label: "Recipes", active: true },
    { id: "influencermixex", label: "Influencers", active: false },
    { id: "brandsmixex", label: "Brands", active: false },
  ];

  // TabContent Data


  const [activeTab, setActiveTab] = useState("receipesex");
  const [topProductactiveTab, settopProductactiveTab] = useState("receipesproex");
  const [topMixlistactiveTab, settopMixlistactiveTab] = useState("receipesmixex");

  const tabContent = [
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {productData && productData.length > 0 ? (
            productData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < productData.length - 1 ? " mb-2" : "")
                      }
                    >
                      <div
                        className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                        style={{
                          backgroundImage: "url(" + product.img + ")",
                        }}
                      ></div>
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={index < productData.length - 1 ? "mb-2" : ""}
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.qty}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.revenue}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.profit}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {productData && productData.length > 0 ? (
            productData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < productData.length - 1 ? " mb-2" : "")
                      }
                    >
                      <div
                        className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                        style={{
                          backgroundImage: "url(" + product.img + ")",
                        }}
                      ></div>
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={index < productData.length - 1 ? "mb-2" : ""}
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.qty}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.revenue}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.profit}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {productData && productData.length > 0 ? (
            productData.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex">
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < productData.length - 1 ? " mb-2" : "")
                      }
                    >
                      <div
                        className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                        style={{
                          backgroundImage: "url(" + product.img + ")",
                        }}
                      ></div>
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.title}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.sku}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={index < productData.length - 1 ? "mb-2" : ""}
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.qty}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.revenue}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.profit}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.profit}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  useEffect(() => {
    fetch("/assets/data/dashboard/stats.json")
      .then((res) => res.json())
      .then((result) => {
        setStatsData(result);
      });

    fetch("/assets/data/dashboard/country.json")
      .then((res) => res.json())
      .then((result) => {
        setCountryData(result);
      });

    fetch("/assets/data/dashboard/product.json")
      .then((res) => res.json())
      .then((result) => {
        setProductData(result);
      });


    //API Integration

    fetch("https://api.barsys.ai/barsysanalytics/public/api/dashboardinfo")
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // console.log(result);
        setData(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      fetch("https://api.barsys.ai/barsysanalytics/public/api/dashboardinfov2")
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // console.log(result);
        setData2(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });



    renderMap();

    document.addEventListener("theme-reload", () => {
      // setServerChartOptions(getServerChartOptions());
      setChartOptions(getChartOptions());
      renderMap();
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    DashUserTileInfo(selectedDateOption.value);
  }, [selectedDateOption, selectedAgeOption, startDate, endDate]);


  useEffect(() => {
    HardwareTileInfo(hardwareselectedDateOption.value,hardwaredeviceOption.value);
  }, [hardwareselectedDateOption,hardwaredeviceOption ,hardwarestartDate, hardwareendDate]);

  useEffect(() => {
    CraftedTileInfo(craftedselectedDateOption.value);
  }, [
    craftedselectedDateOption,
    craftedselectedAgeOption,
    craftedstartDate,
    craftedendDate,
  ]);

  useEffect(() => {
    ActiveTileInfo(activeselectedDateOption.value);
  }, [
    activeselectedDateOption,
    activeselectedAgeOption,
    activestartDate,
    activeendDate,
  ]);

  // useEffect(() => {
  //   const Product = async () => {
  //     try {
  //       const result = await ProductTileInfo();
  //       setReceipeProductTile(result.curatedRecipeData);
  //       setIngredientsProductTile(result.ingredientData);
  //       setBrandsProductTile(result.brandData);
  //       setBarsProductTile(result.influencerRecipe);
  //       setInfluencersProductTile(result.influencerData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   Product();
  // }, []);

  const ReceipeAPI = async (tabId, page) => {
    setIsLoading(true);
    setError(null);

    let endDate = Math.floor(new Date().getTime()/1000.0)
    let startDate = 1546425780;

    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardnewrecipetileinfov2?startTime=${startDate}&endTime=${endDate}&filter=3&page=${page}`
      );
      const result = await response.json();
      setUserReceipeTile(result.userRecipe || [])
      setBarsysRecipeTile(result.curatedRecipe || [])
      setInfluencerReceipeTile(result.influencerRecipe || [])
      setBrandReceipeTile(result.brandRecipe || [])
      // Set data based on the active tab

      if (result.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      } else {
      switch (tabId) {
        case "receipesex":
          setUserReceipeTile(result.userRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "barsysrecipeex":
          setBarsysRecipeTile(result.curatedRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "ingredientseex":
          setInfluencerReceipeTile(result.influencerRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "brandsex":
          setBrandReceipeTile(result.brandRecipe || []);
          setTotalItems(result.length || 0);
          break;
        default:
          break;
      }
      }
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ReceipeAPI(activeTab, currentPage);
  }, [activeTab, currentPage]);


  const TopproductAPI = async (tabId, page,filter,orderRank,key, searchTerm) => {
    setIsLoading(true);
    setError(null);

    try {

      let sortvalue = 0;

      if(key=="views" && orderRank =="asc"){
        sortvalue = 1
        filter = 1
      }else if(key=="crafts" && orderRank =="asc"){
        sortvalue = 1
        filter = 0
      }else if(key=="clicks" && orderRank =="asc"){
        sortvalue = 1
        filter = 3
      }else if(key=="likes" && orderRank =="asc"){
        sortvalue = 1
        filter = 2
      }else if(key=="shares" && orderRank =="asc"){
        sortvalue = 1
        filter = 4
      }

      const productAPI =  `https://api.barsys.ai/barsysanalytics/public/api/dashboardproducttileinfov3?startTime=0&endTime=1731399091&filter=${filter}&page=${page}&datefilter=3&orderRank=${sortvalue}`

      const searchAPI = `https://api.barsys.ai/barsysanalytics/public/api/dashboardproducttileinfosearch?startTime=0&endTime=1731399091&filter=${filter}&page=${page}&datefilter=3&orderRank=${sortvalue}&searchterm=${searchTerm}`

      
      
      if (searchTerm.trim() === "") {
        const response = await fetch(productAPI);
        
        const result = await response.json();
      


        setReceipeProductTile(result.curatedRecipeData || []);
        setIngredientsProductTile(result.ingredientData  || []);
        setBrandsProductTile(result.brandData  || []);
        setBarsProductTile(result.influencerRecipe  || []);
        setInfluencersProductTile(result.influencerData  || []);


      if (result.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      } else {
      switch (tabId) {
        case "receipesproex":
          setReceipeProductTile(result.curatedRecipeData || []);
          // setUserReceipeTile(result.userRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "ingredientsproex":
          setIngredientsProductTile(result.ingredientData  || []);
          // setBarsysRecipeTile(result.curatedRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "brandsproex":
          setBrandsProductTile(result.brandData  || []);
          // setInfluencerReceipeTile(result.influencerRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "barsproex":
          setBarsProductTile(result.influencerRecipe  || []);
          // setBrandReceipeTile(result.brandRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "influencerprosex":
          setBarsProductTile(result.influencerRecipe  || []);
          // setBrandReceipeTile(result.brandRecipe || []);
          setTotalItems(result.length || 0);
          break;
        default:
          break;
      }
      }
    }else{


      const response = await fetch(searchAPI);
        
      const result = await response.json();
    


      setReceipeProductTile(result.curatedRecipeData || []);
      setIngredientsProductTile(result.ingredientData  || []);
      setBrandsProductTile(result.brandData  || []);
      setBarsProductTile(result.influencerRecipe  || []);
      setInfluencersProductTile(result.influencerData  || []);


    if (result.length === 0 && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else {
    switch (tabId) {
      case "receipesproex":
        setReceipeProductTile(result.curatedRecipeData || []);
        // setUserReceipeTile(result.userRecipe || []);
        setTotalItems(result.length || 0);
        break;
      case "ingredientsproex":
        setIngredientsProductTile(result.ingredientData  || []);
        // setBarsysRecipeTile(result.curatedRecipe || []);
        setTotalItems(result.length || 0);
        break;
      case "brandsproex":
        setBrandsProductTile(result.brandData  || []);
        // setInfluencerReceipeTile(result.influencerRecipe || []);
        setTotalItems(result.length || 0);
        break;
      case "barsproex":
        setBarsProductTile(result.influencerRecipe  || []);
        // setBrandReceipeTile(result.brandRecipe || []);
        setTotalItems(result.length || 0);
        break;
      case "influencerprosex":
        setBarsProductTile(result.influencerRecipe  || []);
        // setBrandReceipeTile(result.brandRecipe || []);
        setTotalItems(result.length || 0);
        break;
      default:
        break;
    }
    }
    }
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };



  // const SearchAPI = async (searchTerm, tabId, page, filter, orderRank, key) => {
  //   setIsLoading(true);
  //   setError(null);

  //   try {

  //     let sortvalue = 0;

  //     if(key=="views" && orderRank =="asc"){
  //       sortvalue = 1
  //       filter = 1
  //     }else if(key=="crafts" && orderRank =="asc"){
  //       sortvalue = 1
  //       filter = 0
  //     }else if(key=="clicks" && orderRank =="asc"){
  //       sortvalue = 1
  //       filter = 3
  //     }else if(key=="likes" && orderRank =="asc"){
  //       sortvalue = 1
  //       filter = 2
  //     }else if(key=="shares" && orderRank =="asc"){
  //       sortvalue = 1
  //       filter = 4
  //     }


  //     const response = await fetch(
  //       `https://api.barsys.ai/barsysanalytics/public/api/dashboardproducttileinfosearch?startTime=0&endTime=1731399091&filter=${filter}&page=${page}&datefilter=3&orderRank=${orderRank}&searchterm=${searchTerm}`
  //     );
  //     const result = await response.json();

  //     // setReceipeProductTile(result.curatedRecipeData || []);
  //     // setIngredientsProductTile(result.ingredientData  || []);
  //     // setBrandsProductTile(result.brandData  || []);
  //     // setBarsProductTile(result.influencerRecipe  || []);
  //     // setInfluencersProductTile(result.influencerData  || []);


  //   if (result.length === 0 && currentPage > 1) {
  //     setCurrentPage((prevPage) => prevPage - 1);
  //   } else {
  //   switch (tabId) {
  //     case "receipesproex":
  //       setReceipeProductTile(result.curatedRecipeData || []);
  //       // setUserReceipeTile(result.userRecipe || []);
  //       setTotalItems(result.length || 0);
  //       break;
  //     case "ingredientsproex":
  //       setIngredientsProductTile(result.ingredientData  || []);
  //       // setBarsysRecipeTile(result.curatedRecipe || []);
  //       setTotalItems(result.length || 0);
  //       break;
  //     case "brandsproex":
  //       setBrandsProductTile(result.brandData  || []);
  //       // setInfluencerReceipeTile(result.influencerRecipe || []);
  //       setTotalItems(result.length || 0);
  //       break;
  //     case "barsproex":
  //       setBarsProductTile(result.influencerRecipe  || []);
  //       // setBrandReceipeTile(result.brandRecipe || []);
  //       setTotalItems(result.length || 0);
  //       break;
  //     case "influencerprosex":
  //       setBarsProductTile(result.influencerRecipe  || []);
  //       // setBrandReceipeTile(result.brandRecipe || []);
  //       setTotalItems(result.length || 0);
  //       break;
  //     default:
  //       break;
  //   }
  //   }

  //     console.log("Search API Result:", result);
  //   } catch (error) {
  //     setError("Error fetching search results");
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };








  useEffect(() => {

    TopproductAPI(
      topProductactiveTab,
      currentPage,
      Topproductsortbyoption.value,
      Topproductsortbyoption.order,
      Topproductsortbyoption.key,
      searchTerm
    );
    // TopproductAPI(topProductactiveTab, currentPage,Topproductsortbyoption.value,Topproductsortbyoption.order,Topproductsortbyoption.key);



  }, [searchTerm, topProductactiveTab, currentPage,Topproductsortbyoption]);





  // useEffect(() => {
  //   const Receipe = async () => {
  //     try {
  //       const result = await ReceipesTileInfo();
  //       setUserReceipeTile(result.userRecipe);
  //        setBarsysRecipeTile(result.curatedRecipe)
  //       setInfluencerReceipeTile(result.influencerRecipe);
  //       setBrandReceipeTile(result.brandRecipe);

  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   Receipe();
  // }, []);


  const MixlistAPI = async (tabId, page) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardnewplaylisttileinfov3?datefilter=3&startTime=0&endTime=1781399091&page=${page}`
      );
      const result = await response.json();

      setMixReceipeTile(result.mixlist || []);
      setMixInfluencerTile(result.influencerPlaylist || []);
      setMixBrandTile(result.brandPlaylist || []);


      if (result.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => prevPage - 1);
      } else {
      switch (tabId) {
        case "receipesmixex":
          setMixReceipeTile(result.mixlist|| []);
          // setUserReceipeTile(result.userRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "influencermixex":
          setMixInfluencerTile(result.influencerPlaylist  || []);
          // setBarsysRecipeTile(result.curatedRecipe || []);
          setTotalItems(result.length || 0);
          break;
        case "brandsmixex":
          setMixBrandTile(result.brandPlaylist  || []);
          // setInfluencerReceipeTile(result.influencerRecipe || []);
          setTotalItems(result.length || 0);
          break;
        default:
          break;
      }
      }
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    MixlistAPI(topMixlistactiveTab, currentPage);
  }, [topMixlistactiveTab, currentPage]);


  // useEffect(() => {
  //   const Mixlist = async () => {
  //     try {
  //       const result = await MixlistTileInfo();
  //       setMixReceipeTile(result.userPlaylist);
  //       setMixInfluencerTile(result.influencerPlaylist);
  //       setMixBrandTile(result.brandPlaylist);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   Mixlist();
  // }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const userreceipescurrentItems = UserReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const barsysreceipescurrentItems = BarsysReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const influencerreceipescurrentItems = InfluencerReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const brandreceipescurrentItems = BrandReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const receipesproductscurrentItems = ReceipeProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const ingredientsproductscurrentItems = IngredientsProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const brandproductscurrentItems = BrandsProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // const barsproductsscurrentItems = BarsProductTile.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );
  const influencerscurrentItems = InfluencerProductTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const receipesmixlistcurrentItems = MixReceipeTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const influencersmixlistcurrentItems = MixInfluencersTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const brandmixlistcurrentItems = MixBrandTile.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const userrecipesimageMap = {
    "Hot Toddy with Cinnamon Twist": HotToddyWithCinnamonTwist,
    "Mulled Wine": MulledWine,
    "Nutmeg Infused Old Fashioned": NutmegInfusedOldFashioned,
    "Spiced Apple Cider Sangria": SpicedAppleCiderSangria,
    "Winter Spice Martini": WinterSpiceMartini,
  };

  const baseList = [
    "Bourbon Whiskey",
    "Red Wine",
    "Bourbon Whiskey",
    "White Wine, Brandy",
    "Spiced Rum, Vanilla Vodka",
  ];
  const mixerList = [
    "Honey,Fresh Lemon Juice, Hot Water",
    "Honey",
    "Simple Syrup",
    "Spiced Apple Cider",
    "Cinnamon Syrup, Heavy Cream",
  ];
  const garnishList = [
    "Cinnamon Stick, Lemon Wheel",
    "Whole Orange Sliced, Clove, Cinnamon Stick, Grated Nutmeg",
    "Angostra Bitters, Grated Nutmeg, Orange Twist",
    "Whole Apple Slices, Whole Orange Sliced, Cinnamon Stick",
    "Ground Cinnamon, Cinnamon Stick",
  ];

  const ingredientNamess = Object.keys(userrecipesimageMap);
  const staticDataRecipes = ingredientNamess.map((name1, index) => ({
    name1,
    image: userrecipesimageMap[name1],
    // price: `$${getRandomInt(10, 50)}`,
    baseList: baseList[index],
    mixerList: mixerList[index],
    garnishList: garnishList[index],
    brandName: `Brand ${index + 1}`,
  }));

  const ReceipestabContent = [
    <div className="table-responsive">
      {isLoading ? (
        <div className="spinner-border text-success pt-2"></div>
      ) :staticDataRecipes.length === 0 ? (
        <div>No Records Found</div>
      ) : (
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {userreceipescurrentItems.length > 0 ? (
            userreceipescurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "400px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < userreceipescurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.image ? product.image : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.name}</div>
                      <div>Crafted By:{product.username}</div>
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < userreceipescurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td>Base:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.baseList}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Mixer:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {product.mixerlist} */}
                          {product.mixerlist && product.mixerlist.trim() ? product.mixerlist : "No Mixer"}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Garnish:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {product.garnishlist && product.garnishlist.trim() ? product.garnishlist : "No Garnish"}

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
      )}
    </div>,
    <div className="table-responsive">
    {isLoading ? (
      <div className="spinner-border text-success pt-2"></div>
    ) :staticDataRecipes.length === 0 ? (
      <div>No Records Found</div>
    ) : (
    <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
      <tbody>
        {barsysreceipescurrentItems.length > 0 ? (
          barsysreceipescurrentItems.map((product, index) => (
            <tr key={index} className="product_border">
              <td>
                <div className="d-flex" style={{ width: "400px" }}>
                  <div
                    className={
                      "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                      (index >= 0
                        ? " bg-theme text-theme-900"
                        : " bg-black bg-opacity-50")
                    }
                    style={{
                            fontWeight: "700",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "31px"
                          }}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={
                      "position-relative" +
                      (index < barsysreceipescurrentItems.length - 1
                        ? " mb-2"
                        : "")
                    }
                  >
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="object-fit-cover w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                    <div>Crafted By:{product.username}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {"Barsys"}
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <table
                  className={
                    index < barsysreceipescurrentItems.length - 1 ? "mb-2" : ""
                  }
                >
                  <tbody>
                    <tr>
                      <td>Base:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {product.baseList}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2 text-nowrap">Mixer:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {/* {product.mixerlist} */}
                        {product.mixerlist && product.mixerlist.trim() ? product.mixerlist : "No Mixer"}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2 text-nowrap">Garnish:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                      {product.garnishlist && product.garnishlist.trim() ? product.garnishlist : "No Garnish"}

                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No records found</td>
          </tr>
        )}
      </tbody>
    </table>
    )}
    </div>,
    <div className="table-responsive">
    {isLoading ? (
      <div className="spinner-border text-success pt-2"></div>
    ) :staticDataRecipes.length === 0 ? (
      <div>No Records Found</div>
    ) : (
    <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
      <tbody>
        {influencerreceipescurrentItems.length > 0 ? (
          influencerreceipescurrentItems.map((product, index) => (
            <tr key={index} className="product_border">
              <td>
                <div className="d-flex" style={{ width: "400px" }}>
                  <div
                    className={
                      "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                      (index >= 0
                        ? " bg-theme text-theme-900"
                        : " bg-black bg-opacity-50")
                    }
                    style={{
                            fontWeight: "700",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "31px"
                          }}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={
                      "position-relative" +
                      (index < influencerreceipescurrentItems.length - 1
                        ? " mb-2"
                        : "")
                    }
                  >
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="object-fit-cover w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                    <div>Crafted By:{product.username}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {"Barsys"}
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <table
                  className={
                    index < influencerreceipescurrentItems.length - 1 ? "mb-2" : ""
                  }
                >
                  <tbody>
                    <tr>
                      <td>Base:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {product.baseList}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2 text-nowrap">Mixer:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {/* {product.mixerlist} */}
                        {product.mixerlist && product.mixerlist.trim() ? product.mixerlist : "No Mixer"}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2 text-nowrap">Garnish:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                      {product.garnishlist && product.garnishlist.trim() ? product.garnishlist : "No Garnish"}

                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No records found</td>
          </tr>
        )}
      </tbody>
    </table>
    )}
  </div>,
    <div className="table-responsive">
    {isLoading ? (
      <div className="spinner-border text-success pt-2"></div>
    ) :staticDataRecipes.length === 0 ? (
      <div>No Records Found</div>
    ) : (
    <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
      <tbody>
        {brandreceipescurrentItems.length > 0 ? (
          brandreceipescurrentItems.map((product, index) => (
            <tr key={index} className="product_border">
              <td>
                <div className="d-flex" style={{ width: "400px" }}>
                  <div
                    className={
                      "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                      (index >= 0
                        ? " bg-theme text-theme-900"
                        : " bg-black bg-opacity-50")
                    }
                    style={{
                            fontWeight: "700",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "31px"
                          }}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={
                      "position-relative" +
                      (index < brandreceipescurrentItems.length - 1
                        ? " mb-2"
                        : "")
                    }
                  >
                    <Image
                      src={`${product.image ? product.image : glassshimmer}`}
                      className="object-fit-cover w-80px h-80px"
                    />
                  </div>
                  <div className="flex-1 ps-3">
                    <div className="fw-500 text-inverse">{product.name}</div>
                    <div>Crafted By:{product.username}</div>
                    <div className="mb-1">
                      <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                        {"Barsys"}
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <table
                  className={
                    index < brandreceipescurrentItems.length - 1 ? "mb-2" : ""
                  }
                >
                  <tbody>
                    <tr>
                      <td>Base:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {product.baseList}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2 text-nowrap">Mixer:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                        {/* {product.mixerlist} */}
                        {product.mixerlist && product.mixerlist.trim() ? product.mixerlist : "No Mixer"}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2 text-nowrap">Garnish:</td>
                      <td className="text-inverse text-opacity-75 fw-500 pe-5">
                      {product.garnishlist && product.garnishlist.trim() ? product.garnishlist : "No Garnish"}

                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No records found</td>
          </tr>
        )}
      </tbody>
    </table>
    )}
  </div>,
  ];



 

  // Handle page change
  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  // Get current items based on activeTab and currentPage
  // Determine the items to display based on the active tab
  const getCurrentItems = () => {
    switch (activeTab) {
      case "receipesex":
        return UserReceipeTile;
      case "barsysrecipeex":
        return BarsysReceipeTile;
      case "ingredientseex":
        return InfluencerReceipeTile;
      case "brandsex":
        return BrandReceipeTile;
      default:
        return [];
    }
  };

  const currentItems = getCurrentItems();
  // const totalItems = currentItems.length;

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setCurrentPage(1);
    // ReceipeAPI(tabId, 1);
   // Reset to the first page when switching tabs
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    ReceipeAPI(activeTab, pageNumber);
  };




  const getCurrentItemsTopProduct= () => {
    switch (topProductactiveTab) {
      case "receipesproex":
        return ReceipeProductTile;
      case "ingredientsproex":
        return IngredientsProductTile;
      case "brandsproex":
        return BrandsProductTile;
      // case "barsproex":
      //   return BarsProductTile;
      case "influencerprosex":
          return InfluencerProductTile;
      default:
        return [];
    }
  };

  
  const currentItemsTopProduct = getCurrentItemsTopProduct();
  // const totalItems = currentItems.length;

  const handleTabClickTopProduct = (tabId) => {
    settopProductactiveTab(tabId);
    setCurrentPage(1);
    // ReceipeAPI(tabId, 1);
   // Reset to the first page when switching tabs
  };
  
  const handlePageClickTopProduct = (pageNumber) => {
    setCurrentPage(pageNumber);
    TopproductAPI(topProductactiveTab, pageNumber);
  };

  
  
  const getCurrentItemsMixlist= () => {
    switch (topMixlistactiveTab) {
      case "receipesmixex":
        return MixReceipeTile;
      case "influencermixex":
            return MixInfluencersTile;
      case "brandsmixex":
          return MixBrandTile;
      default:
        return [];
    }
  };
  
  const currentItemsMixlist = getCurrentItemsMixlist();
  // const totalItems = currentItems.length;

  const handleTabClickMixlist = (tabId) => {
    settopMixlistactiveTab(tabId);
    setCurrentPage(1);
    // ReceipeAPI(tabId, 1);
   // Reset to the first page when switching tabs
  };

  const handlePageClickMixlist = (pageNumber) => {
    setCurrentPage(pageNumber);
    MixlistAPI(topMixlistactiveTab, pageNumber);
  };









  const ReceipestabContentEX = {
    receipesex: (<div className="table-responsive">
    {/* Error Message */}
    {error && <div className="alert alert-danger">{error}</div>}

    {/* Loading Spinner */}
    {isLoading ? (
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
      <>
        {/* No Records Found */}
        {!isLoading && currentItems.length === 0 && !error && (
          <div>No Records Found</div>
        )}

        {/* Recipe Table */}
        {currentItems.length > 0 && (
          <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
            <tbody>
              {currentItems.map((product, index) => (
                <tr key={index} className="product_border">
                  <td>
                    <div className="d-flex" style={{ width: "450px" }}>
                      {/* Serial Number */}
                      <div
                        className={
                          "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px " +
                          (index >= 0 ? "bg-theme text-theme-900" : "bg-black bg-opacity-50")
                        }
                        style={{
                          fontWeight: "700",
                          minHeight: "20px",
                          minWidth: "20px",
                        }}
                      >
                        {(currentPage - 1) * itemsPerPageNewRecipe+ index + 1}
                      </div>
                      
                      {/* Product Image */}
                      <div
                        className={
                          "position-relative" +
                          (index < currentItems.length - 1 ? " mb-2" : "")
                        }
                      >
                        <Image
                          src={product.image ? product.image : glassshimmer}
                          className="object-fit-cover w-80px h-80px"
                          
                          width={80}
                          height={80}
                          loading="lazy"
                        />
                
                      </div>
                      
                      {/* Product Details */}
                      <div className="flex-1 ps-3">
                        <div className="fw-500 text-inverse">{product.name}</div>
                        <div>Crafted By: </div>
                        <div className="mb-1">
                          <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.username ? product.username : "Barsys"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </td>
                  
                  {/* Recipe Details */}
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>Base:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.baseList}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Mixer:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.mixerlist}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Garnish:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.garnishlist}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        
      </>
    )}
  </div>),
     barsysrecipeex: (<div className="table-responsive">
    {/* Error Message */}
    {error && <div className="alert alert-danger">{error}</div>}

    {/* Loading Spinner */}
    {isLoading ? (
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
      <>
        {/* No Records Found */}
        {!isLoading && currentItems.length === 0 && !error && (
          <div>No Records Found</div>
        )}

        {/* Recipe Table */}
        {currentItems.length > 0 && (
          <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
            <tbody>
              {currentItems.map((product, index) => (
                <tr key={index} className="product_border">
                  <td>
                    <div className="d-flex" style={{ width: "450px" }}>
                      {/* Serial Number */}
                      <div
                        className={
                          "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px " +
                          (index >= 0 ? "bg-theme text-theme-900" : "bg-black bg-opacity-50")
                        }
                        style={{
                          fontWeight: "700",
                          minHeight: "20px",
                          minWidth: "20px",
                        }}
                      >
                        {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                      </div>
                      
                      {/* Product Image */}
                      <div
                        className={
                          "position-relative" +
                          (index < currentItems.length - 1 ? " mb-2" : "")
                        }
                      >
                        <Image
                          src={product.image ? product.image : glassshimmer}
                          className="object-fit-cover w-80px h-80px"
                          
                          width={80}
                          height={80}
                          loading="lazy"
                        />
                      </div>
                      
                      {/* Product Details */}
                      <div className="flex-1 ps-3">
                        <div className="fw-500 text-inverse">{product.name}</div>
                        <div>Crafted By: </div>
                        <div className="mb-1">
                          <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.username ? product.username : "Barsys"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </td>
                  
                  {/* Recipe Details */}
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>Base:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.baseList}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Mixer:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.mixerlist}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Garnish:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.garnishlist}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        
      </>
    )}
  </div>),
    ingredientseex: (<div className="table-responsive">
    {/* Error Message */}
    {error && <div className="alert alert-danger">{error}</div>}

    {/* Loading Spinner */}
    {isLoading ? (
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
      <>
        {/* No Records Found */}
        {!isLoading && currentItems.length === 0 && !error && (
          <div>No Records Found</div>
        )}

        {/* Recipe Table */}
        {currentItems.length > 0 && (
          <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
            <tbody>
              {currentItems.map((product, index) => (
                <tr key={index} className="product_border">
                  <td>
                    <div className="d-flex" style={{ width: "450px" }}>
                      {/* Serial Number */}
                      <div
                        className={
                          "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px " +
                          (index >= 0 ? "bg-theme text-theme-900" : "bg-black bg-opacity-50")
                        }
                        style={{
                          fontWeight: "700",
                          minHeight: "20px",
                          minWidth: "20px",
                        }}
                      >
                        {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                      </div>
                      
                      {/* Product Image */}
                      <div
                        className={
                          "position-relative" +
                          (index < currentItems.length - 1 ? " mb-2" : "")
                        }
                      >
                        <Image
                          src={product.image ? product.image : glassshimmer}
                          className="object-fit-cover w-80px h-80px"
                          
                          width={80}
                          height={80}
                          loading="lazy"
                        />
                      </div>
                      
                      {/* Product Details */}
                      <div className="flex-1 ps-3">
                        <div className="fw-500 text-inverse">{product.name}</div>
                        <div>Crafted By: </div>
                        <div className="mb-1">
                          <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.username ? product.username : "Barsys"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </td>
                  
                  {/* Recipe Details */}
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>Base:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.baseList}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Mixer:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.mixerlist}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Garnish:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.garnishlist}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        
      </>
    )}
  </div>),
    brandsex: (<div className="table-responsive">
    {/* Error Message */}
    {error && <div className="alert alert-danger">{error}</div>}

    {/* Loading Spinner */}
    {isLoading ? (
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
      <>
        {/* No Records Found */}
        {!isLoading && currentItems.length === 0 && !error && (
          <div>No Records Found</div>
        )}

        {/* Recipe Table */}
        {currentItems.length > 0 && (
          <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
            <tbody>
              {currentItems.map((product, index) => (
                <tr key={index} className="product_border">
                  <td>
                    <div className="d-flex" style={{ width: "450px" }}>
                      {/* Serial Number */}
                      <div
                        className={
                          "rounded-2 d-flex align-items-center justify-content-center mt-4 me-4 w-20px h-20px " +
                          (index >= 0 ? "bg-theme text-theme-900" : "bg-black bg-opacity-50")
                        }
                        style={{
                          fontWeight: "700",
                          minHeight: "20px",
                          minWidth: "20px",
                        }}
                      >
                        {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                      </div>
                      
                      {/* Product Image */}
                      <div
                        className={
                          "position-relative" +
                          (index < currentItems.length - 1 ? " mb-2" : "")
                        }
                      >
                        <Image
                          src={product.image ? product.image : glassshimmer}
                          className="object-fit-cover w-80px h-80px"
                          
                          width={80}
                          height={80}
                          loading="lazy"
                        />
                      </div>
                      
                      {/* Product Details */}
                      <div className="flex-1 ps-3">
                        <div className="fw-500 text-inverse">{product.name}</div>
                        <div>Crafted By: </div>
                        <div className="mb-1">
                          <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.username ? product.username : "Barsys"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </td>
                  
                  {/* Recipe Details */}
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>Base:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.baseList}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Mixer:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.mixerlist}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 text-nowrap">Garnish:</td>
                          <td className="text-inverse text-opacity-75 fw-500 pe-5">
                            {product.garnishlist}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        
      </>
    )}
  </div>),
};

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function toImagePath(name) {
    return `./assets/images/${name.replace(/\s+/g, "_").toLowerCase()}.jpg`;
  }

  const imageMap = {
    "Cranberry Juice": CranberryJuice,
    "Elderflower Liqueur": ElderflowerLiqueur,
    "Grapefruit Juice": GrapefruitJuice,
    "Lemon Juice": LemonJuice,
    "Lime Juice": LimeJuice,
    "Orange Juice": OrangeJuice,
    "Simple Syrup": SimpleSyrup,
    "Soda Water": SodaWater,
    Tequila: Tequila,
    "Tonic Water": TonicWater,
    "Triple Sec": TripleSec,
    Vodka: Vodka,
    Whiskey: Whiskey,
    "White Rum": WhiteRum,
  };

  const ingredientNames = Object.keys(imageMap);
  const staticData = ingredientNames.map((name, index) => ({
    name,
    baseSponsorImage: imageMap[name],
    // price: `$${getRandomInt(10, 50)}`,
    brandName: `Brand`,
  }));

  const slicedData = staticData.slice(0, 5);

  const TPtabContent = [
    <div className="table-responsive">
      {isLoading ? (
        <div className="spinner-border text-success pt-2"></div>
      ) : receipesproductscurrentItems.length === null ? (
        <div>No Records Found</div>
      ) : (
        <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
          <tbody>
            {receipesproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ minWidth: "350px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < receipesproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.image ? product.image : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.name}</div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < receipesproductscurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.countmake}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.likeCount}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.makeCount}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.viewCount}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.sharedCount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {ingredientsproductscurrentItems &&
          ingredientsproductscurrentItems.length > 0 ? (
            ingredientsproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "350px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < ingredientsproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={
                          product.baseSponsorImage
                            ? product.baseSponsorImage
                            : glassshimmer
                        }
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">{product.name}</div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < ingredientsproductscurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Crafts:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.repeatCount}
                        </td>
                        {/* <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.likeCount}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {product.makeCount}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.sharedCount}
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandproductscurrentItems && brandproductscurrentItems.length > 0 ? (
            brandproductscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "350px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandproductscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.brandIcon ? product.brandIcon : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.brandName}
                      </div>
                      {product.price}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandproductscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Crafts:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.repeatCount}
                        </td>
                        {/* <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          0
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                         0
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    // <div className="table-responsive">
    //   <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
    //     <tbody>
    //       {brandproductscurrentItems && brandproductscurrentItems.length > 0 ? (
    //         brandproductscurrentItems.map((product, index) => (
    //           <tr key={index} className="product_border">
    //             <td>
    //               <div className="d-flex" style={{ width: "350px" }}>
    //                 <div
    //                   className={
    //                     "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
    //                     (index >= 0
    //                       ? " bg-theme text-theme-900"
    //                       : " bg-black bg-opacity-50")
    //                   }
    //                   style={{
    //                           fontWeight: "700",
    //                           minHeight: "20px",
    //                           minWidth: "20px",
    //                           marginTop: "31px"
    //                         }}
    //                 >
    //                   {index + 1}
    //                 </div>
    //                 <div
    //                   className={
    //                     "position-relative" +
    //                     (index < brandproductscurrentItems.length - 1
    //                       ? " mb-2"
    //                       : "")
    //                   }
    //                 >
    //                   <Image
    //                     src={`${product.img ? product.img : glassshimmer}`}
    //                     className="object-fit-cover w-80px h-80px"
    //                   />
    //                 </div>
    //                 <div className="flex-1 ps-3">
    //                   <div className="fw-500 text-inverse">{product.title}</div>
    //                   {product.price}
    //                   <div className="mb-1">
    //                     <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
    //                       {product.sku}
    //                     </small>
    //                   </div>
    //                 </div>
    //               </div>
    //             </td>
    //             <td>
    //               <table
    //                 className={
    //                   index < brandproductscurrentItems.length - 1 ? "mb-2" : ""
    //                 }
    //               >
    //                 <tbody>
    //                   <tr>
    //                     <td className="pe-2">Views:</td>
    //                     <td className="text-inverse text-opacity-75 fw-500 pe-5">
    //                       0
    //                     </td>
    //                     <td className="pe-2">Likes:</td>
    //                     <td className="text-inverse text-opacity-75 fw-500 pe-5">
    //                       0
    //                     </td>
    //                     <td className="pe-2">Crafted:</td>
    //                     <td className="text-inverse text-opacity-75 fw-500 ">
    //                       0
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td className="pe-2 text-nowrap">Clicks:</td>
    //                     <td className="text-inverse text-opacity-75 fw-500 pe-5">
    //                       0
    //                     </td>
    //                     <td className="pe-2 text-nowrap">Shares:</td>
    //                     <td className="text-inverse text-opacity-75 fw-500 pe-5">
    //                       0
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </td>
    //           </tr>
    //         ))
    //       ) : (
    //         <tr>
    //           <td colSpan="5">No records found</td>
    //         </tr>
    //       )}
    //     </tbody>
    //   </table>
    // </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {influencerscurrentItems && influencerscurrentItems.length > 0 ? (
            influencerscurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "350px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < influencerscurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${product.img ? product.img : glassshimmer}`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.username}
                      </div>
                      {product.email}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {"Barsys"}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < influencerscurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Crafts:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.repeatCount}
                        </td>
                        {/* <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          0
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  const TPtabContentEX = {
    receipesproex: (
      <div className="table-responsive">
        {/* Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
  
        {/* Loading Spinner */}
        {isLoading ? (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {/* No Records Found */}
            {!isLoading && currentItemsTopProduct.length === 0 && !error && (
              <div>No Records Found</div>
            )}
  
            {/* Recipe Table */}
            {currentItemsTopProduct.length > 0 && (
              <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
                <tbody>
                  {currentItemsTopProduct.map((product, index) => (
                    <tr key={index} className="product_border">
                      <td>
                        <div className="d-flex" style={{ minWidth: "60vw"}}>
                          {/* Serial Number */}
                          <div
                            className={
                              "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px " +
                              (index >= 0
                                ? "bg-theme text-theme-900"
                                : "bg-black bg-opacity-50")
                            }
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                          >
                            {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                          </div>
  
                          {/* Product Image */}
                          <div
                            className={
                              "position-relative" +
                              (index < currentItemsTopProduct.length - 1
                                ? " mb-2"
                                : "")
                            }
                          >
                            <Image
                              src={product.image ? product.image : glassshimmer}
                              className="object-fit-cover w-80px h-80px"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
  
                          {/* Product Details */}
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">{product.name}</div>
                            Crafted By: {product.username}
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                {product.brandName}
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
  
                      {/* Recipe Details */}
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-2">Views:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.countmake}
                              </td>
                              <td className="pe-2">Likes:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.likeCount}
                              </td>
                              <td className="pe-2">Crafted:</td>
                              <td className="text-inverse text-opacity-75 fw-500">
                                {product.makeCount}
                              </td>
                            </tr>
                            <tr>
                              <td className="pe-2 text-nowrap">Clicks:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.viewCount}
                              </td>
                              <td className="pe-2 text-nowrap">Shares:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.sharedCount}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    ),
  
    ingredientsproex: (
      <div className="table-responsive">
        {/* Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
  
        {/* Loading Spinner */}
        {isLoading ? (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {/* No Records Found */}
            {!isLoading &&
              currentItemsTopProduct.length === 0 &&
              !error && <div>No Records Found</div>}
  
            {/* Ingredients Table */}
            {currentItemsTopProduct.length > 0 && (
              <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
                <tbody>
                  {currentItemsTopProduct.map((product, index) => (
                    <tr key={index} className="product_border">
                      <td>
                        <div className="d-flex" style={{ minWidth: "60vw"}}>
                          {/* Serial Number */}
                          <div
                            className={
                              "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px " +
                              (index >= 0
                                ? "bg-theme text-theme-900"
                                : "bg-black bg-opacity-50")
                            }
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                          >
                            {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                          </div>
  
                          {/* Product Image */}
                          <div
                            className={
                              "position-relative" +
                              (index < currentItemsTopProduct.length - 1
                                ? " mb-2"
                                : "")
                            }
                          >
                            <Image
                              src={
                                product.baseSponsorImage
                                  ? product.baseSponsorImage
                                  : glassshimmer
                              }
                              className="object-fit-cover w-80px h-80px"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
  
                          {/* Product Details */}
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">
                              {product.name}
                            </div>
                            {product.price}
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                {product.brandName}
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
  
                      {/* Product Metrics */}
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-2">Crafts:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.repeatCount}
                              </td>
                              {/* <td className="pe-2">Likes:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.likeCount}
                              </td>
                              <td className="pe-2">Crafted:</td>
                              <td className="text-inverse text-opacity-75 fw-500">
                                {product.makeCount}
                              </td>
                            </tr>
                            <tr>
                              <td className="pe-2 text-nowrap">Clicks:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                0
                              </td>
                              <td className="pe-2 text-nowrap">Shares:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.sharedCount}
                              </td> */}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    ),
  
    brandsproex: (
      <div className="table-responsive">
        {/* Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
  
        {/* Loading Spinner */}
        {isLoading ? (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {/* No Records Found */}
            {!isLoading &&
              currentItemsTopProduct.length === 0 &&
              !error && <div>No Records Found</div>}
  
            {/* Brands Table */}
            {currentItemsTopProduct.length > 0 && (
              <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
                <tbody>
                  {currentItemsTopProduct.map((product, index) => (
                    <tr key={index} className="product_border">
                      <td>
                        <div className="d-flex" style={{ minWidth: "60vw"}}>
                          {/* Serial Number */}
                          <div
                            className={
                              "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px " +
                              (index >= 0
                                ? "bg-theme text-theme-900"
                                : "bg-black bg-opacity-50")
                            }
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                          >
                            {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                          </div>
  
                          {/* Product Image */}
                          <div
                            className={
                              "position-relative" +
                              (index < currentItemsTopProduct.length - 1
                                ? " mb-2"
                                : "")
                            }
                          >
                            <Image
                              src={product.brandIcon ? product.brandIcon : glassshimmer}
                              className="object-fit-cover w-80px h-80px"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
  
                          {/* Product Details */}
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">{product.brandName}</div>
                            {product.price}
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                {"Barsys"}
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
  
                      {/* Product Metrics */}
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-2">Crafts:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                              {product.repeatCount}
                              </td>
                              {/* <td className="pe-2">Likes:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.likeCount}
                              </td>
                            </tr>
                            <tr>
                              <td className="pe-2 text-nowrap">Clicks:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.viewCount}
                              </td>
                              <td className="pe-2 text-nowrap">Shares:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.sharedCount}
                              </td> */}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    ),
  
    influencerprosex: (
      <div className="table-responsive">
        {/* Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
  
        {/* Loading Spinner */}
        {isLoading ? (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {/* No Records Found */}
            {!isLoading &&
              currentItemsTopProduct.length === 0 &&
              !error && <div>No Records Found</div>}
  
            {/* Influencers Table */}
            {currentItemsTopProduct.length > 0 && (
              <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
                <tbody>
                  {currentItemsTopProduct.map((product, index) => (
                    <tr key={index} className="product_border">
                      <td>
                        <div className="d-flex" style={{ minWidth: "60vw"}}>
                          {/* Serial Number */}
                          <div
                            className={
                              "rounded-2 d-flex align-items-center justify-content-center me-4 w-20px h-20px " +
                              (index >= 0
                                ? "bg-theme text-theme-900"
                                : "bg-black bg-opacity-50")
                            }
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                              marginTop: "31px"
                            }}
                          >
                            {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                          </div>
  
                          {/* Influencer Image */}
                          <div
                            className={
                              "position-relative" +
                              (index < currentItemsTopProduct.length - 1
                                ? " mb-2"
                                : "")
                            }
                          >
                            <Image
                              src={product.img ? product.img : glassshimmer}
                              className="object-fit-cover w-80px h-80px"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
  
                          {/* Influencer Details */}
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">
                              {product.username}
                            </div>
                            {product.email}
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                Barsys
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                  <table
                    className={
                      index < currentItemsTopProduct.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Crafts:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {product.repeatCount}
                        </td>
                        {/* <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          0
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          0
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    ),
  };
  

  const Mixlistcontent = [
    <div className="table-responsive">
      {isLoading ? (
        <div className="spinner-border text-success pt-2"></div>
      ) : receipesmixlistcurrentItems.length === 0 ? (
        <div>No Records Found</div>
      ) : (
        <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
          <tbody>
            {receipesmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "380px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < receipesmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <table
                    className={
                      index < receipesmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Recipes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}{product.recipeCount}
                        </td>
                       </tr>
                    </tbody>
                  </table>
                </td>        



                
                <td>
                  <table
                    className={
                      index < receipesmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}{product.likeCount}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}{product.likeCount}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}{product.likeCount}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}{product.shareCount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {influencersmixlistcurrentItems &&
          influencersmixlistcurrentItems.length > 0 ? (
            influencersmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < influencersmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < influencersmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2">Crafted:</td>
                        <td className="text-inverse text-opacity-75 fw-500 ">
                          {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {getRandomInt(1000, 10000)}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {getRandomInt(1000, 10000)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
    <div className="table-responsive">
      <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
        <tbody>
          {brandmixlistcurrentItems && brandmixlistcurrentItems.length > 0 ? (
            brandmixlistcurrentItems.map((product, index) => (
              <tr key={index} className="product_border">
                <td>
                  <div className="d-flex" style={{ width: "450px" }}>
                    <div
                      className={
                        "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px" +
                        (index >= 0
                          ? " bg-theme text-theme-900"
                          : " bg-black bg-opacity-50")
                      }
                      style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                    >
                      {index + 1}
                    </div>
                    <div
                      className={
                        "position-relative" +
                        (index < brandmixlistcurrentItems.length - 1
                          ? " mb-2"
                          : "")
                      }
                    >
                      <Image
                        src={`${
                          product.playListImage
                            ? product.playListImage
                            : glassshimmer
                        }`}
                        className="object-fit-cover w-80px h-80px"
                      />
                    </div>
                    <div className="flex-1 ps-3">
                      <div className="fw-500 text-inverse">
                        {product.playListName}
                      </div>
                      Crafted By: {product.username}
                      <div className="mb-1">
                        <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                          {product.brandName}
                        </small>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <table
                    className={
                      index < brandmixlistcurrentItems.length - 1 ? "mb-2" : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Views:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}
                        </td>
                        <td className="pe-2">Likes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}
                        </td>
                      </tr>
                      <tr>
                        <td className="pe-2 text-nowrap">Clicks:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}
                        </td>
                        <td className="pe-2 text-nowrap">Shares:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>,
  ];

  const MixlistcontentEX = {
    receipesmixex: (
      <div className="table-responsive">
        {/* Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
  
        {/* Loading Spinner */}
        {isLoading ? (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {/* No Records Found */}
            {!isLoading && currentItemsMixlist.length === 0 && !error && (
              <div>No Records Found</div>
            )}
  
            {/* Recipes Mix List Table */}
            {currentItemsMixlist.length > 0 && (
              <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
                <tbody>
                  {currentItemsMixlist.map((product, index) => (
                    <tr key={index} className="product_border">
                      <td>
                        <div className="d-flex" style={{ width: "700px" }}>
                          {/* Serial Number */}
                          <div
                            className={
                              "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px " +
                              (index >= 0
                                ? "bg-theme text-theme-900"
                                : "bg-black bg-opacity-50")
                            }
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                          >
                           {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                          </div>
  
                          {/* Product Image */}
                          <div className={
                              "position-relative" +
                              (index < currentItemsMixlist.length - 1
                                ? " mb-2"
                                : "")
                            }
                          >
                            <Image
                              src={
                                product.playListImage
                                  ? product.playListImage
                                  : glassshimmer
                              }
                              className="object-fit-cover w-80px h-80px"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
  
                          {/* Product Details */}
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">
                              {product.playListName}
                            </div>
                            Crafted By: {product.username}
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                {product.brandName}
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>


                      <td style={{ width: "400px" }}>
                  <table
                    className={
                      index < receipesmixlistcurrentItems.length - 1
                        ? "mb-2"
                        : ""
                    }
                  >
                    <tbody>
                      <tr>
                        <td className="pe-2">Recipes:</td>
                        <td className="text-inverse text-opacity-75 fw-500 pe-5">
                          {/* {getRandomInt(1000, 10000)} */}{product.recipeCount}
                        </td>
                       </tr>
                    </tbody>
                  </table>
                </td>        
  
                      {/* Metrics */}
                      <td style={{ width: "400px" }}>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-2">Views:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                              {product.likeCount}
                              </td>
                              <td className="pe-2">Likes:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.likeCount}
                              </td>
                            </tr>
                            <tr>
                              <td className="pe-2 text-nowrap">Clicks:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                              {product.likeCount}
                              </td>
                              <td className="pe-2 text-nowrap">Shares:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                              {product.shareCount}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>



                      <td style={{ width: "400px" }}>
                      <button type="button" className="btn btn-success mb-1 me-1">Recipe Details</button>
                      
                </td>  
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    ),
  
    influencermixex: (
      <div className="table-responsive">
        {/* Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
  
        {/* Loading Spinner */}
        {isLoading ? (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {/* No Records Found */}
            {!isLoading && currentItemsMixlist.length === 0 && !error && (
              <div>No Records Found</div>
            )}
  
            {/* Influencers Mix List Table */}
            {currentItemsMixlist.length > 0 && (
              <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
                <tbody>
                  {currentItemsMixlist.map((product, index) => (
                    <tr key={index} className="product_border">
                      <td>
                        <div className="d-flex" style={{ width: "897px" }}>
                          {/* Serial Number */}
                          <div
                            className={
                              "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px " +
                              (index >= 0
                                ? "bg-theme text-theme-900"
                                : "bg-black bg-opacity-50")
                            }
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                          >
                            {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                          </div>
  
                          {/* Product Image */}
                          <div className={
                              "position-relative" +
                              (index < currentItemsMixlist.length - 1
                                ? " mb-2"
                                : "")
                            }
                          >
                          <Image
                              src={
                                product.playListImage
                                  ? product.playListImage
                                  : glassshimmer
                              }
                              className="object-fit-cover w-80px h-80px"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
  
                          {/* Product Details */}
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">
                              {product.playListName}
                            </div>
                            Crafted By: {product.username}
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                {product.brandName}
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
  
                      {/* Metrics */}
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-2">Views:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.viewCount}
                              </td>
                              <td className="pe-2">Likes:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.likeCount}
                              </td>
                              <td className="pe-2">Crafted:</td>
                              <td className="text-inverse text-opacity-75 fw-500">
                                {product.makeCount}
                              </td>
                            </tr>
                            <tr>
                              <td className="pe-2 text-nowrap">Clicks:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.clickCount}
                              </td>
                              <td className="pe-2 text-nowrap">Shares:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.sharedCount}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    ),
  
    brandsmixex: (
      <div className="table-responsive">
        {/* Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
  
        {/* Loading Spinner */}
        {isLoading ? (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            {/* No Records Found */}
            {!isLoading && currentItemsMixlist.length === 0 && !error && (
              <div>No Records Found</div>
            )}
  
            {/* Brands Mix List Table */}
            {currentItemsMixlist.length > 0 && (
              <table className="w-100 mb-3 mt-3 small align-middle text-nowrap top-products-table">
                <tbody>
                  {currentItemsMixlist.map((product, index) => (
                    <tr key={index} className="product_border">
                      <td>
                        <div className="d-flex" style={{ width: "897px" }}>
                          {/* Serial Number */}
                          <div
                            className={
                              "rounded-2 d-flex align-items-center justify-content-center mt-3 me-4 w-20px h-20px " +
                              (index >= 0
                                ? "bg-theme text-theme-900"
                                : "bg-black bg-opacity-50")
                            }
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                          >
                            {(currentPage - 1) * itemsPerPageNewRecipe + index + 1}
                          </div>
  
                          {/* Product Image */}
                          <div className={
                              "position-relative" +
                              (index < currentItemsMixlist.length - 1
                                ? " mb-2"
                                : "")
                            }
                          >
                            <Image
                              src={
                                product.playListImage
                                  ? product.playListImage
                                  : glassshimmer
                              }
                              className="object-fit-cover w-80px h-80px"
                              width={80}
                              height={80}
                              loading="lazy"
                            />
                          </div>
  
                          {/* Product Details */}
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">
                              {product.playListName}
                            </div>
                            Crafted By: {product.username}
                            <div className="mb-1">
                              <small className="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-inverse bg-opacity-25 text-inverse text-opacity-75 pt-5px">
                                {product.brandName}
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
  
                      {/* Metrics */}
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-2">Views:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.viewCount}
                              </td>
                              <td className="pe-2">Likes:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.likeCount}
                              </td>
                            </tr>
                            <tr>
                              <td className="pe-2 text-nowrap">Clicks:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.clickCount}
                              </td>
                              <td className="pe-2 text-nowrap">Shares:</td>
                              <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                {product.sharedCount}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    ),
  };
  

  // Handle Start Date
  const handleChangeStart = (date) => {
    setStartDate(date);
  };

  const handleChangeStartCrafted = (date) => {
    setcraftedStartDate(date);
  };

  const handleChangeStartHardware = (date) => {
    sethardwareStartDate(date);
  };


  const handleChangeStartActive = (date) => {
    setActiveStartDate(date);
  };


  // Handle End Date

  const handleChangeEnd = (date) => {
    setEndDate(date);
  };

  const handleChangeEndCrafted = (date) => {
    setcraftedEndDate(date);
  };

  const handleChangeEndHardware = (date) => {
    sethardwareEndDate(date);
  };

  const handleChangeEndActive = (date) => {
    setActiveEndDate(date);
  };


  //Handle Age Option

  const handleAgeOptionChange = (selectedOption) => {
    setSelectedAgeOption(selectedOption);
  };

  const handleAgeOptionChangeCrafted = (selectedOption) => {
    setcraftedSelectedAgeOption(selectedOption);
  };


  const handleAgeOptionChangeActive = (selectedOption) => {
    setActiveSelectedAgeOption(selectedOption);
  };

  //Handle Device Option

  const handleDeviceOptionChangeHardware = (selectedOption) => {
    sethardwaredeviceOption(selectedOption);
  };
  // Handle Top Product Sort
  const handleSortOptionChangeTopProduct = (selectedOption) => {
    settopproductsortby(selectedOption);
  };


  // Time Calculate

  const dateToTimestampInSeconds = (date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const calculateTimestampForAge = (age) => {
    const birthDate = new Date();
    birthDate.setFullYear(birthDate.getFullYear() - age);
    birthDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    return Math.floor(birthDate.getTime() / 1000);
  };

  const DashUserTileInfo = async (filterValue) => {
    try {
      let startTime = 0;
      let endTime = 0;
      if (selectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(startDate);
        endTime = dateToTimestampInSeconds(endDate);
      }

      let ageStart = 0;
      let ageEnd = timestampInSeconds; // Default to current timestamp if no age filter is applied

      if (selectedAgeOption.value !== "0") {
        // Age filter selected
        const [startAge, endAge] = selectedAgeOption.value.split("-");
        ageStart = calculateTimestampForAge(parseInt(startAge));
        ageEnd = calculateTimestampForAge(parseInt(endAge));
        // console.log(ageStart, ageEnd);
      }

      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardusertileinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=User&agestart=${ageStart}&ageend=${ageEnd}`
      );
      const result = await response.json();
      
      setDataUserTile(result.dateswise);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  const HardwareTileInfo = async (filterValue,device) => {
    try {
      let startTime = 0;
      let endTime = 0;

      if (hardwareselectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(hardwarestartDate);
        endTime = dateToTimestampInSeconds(hardwareendDate);
      }

    const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/hardwarecharttileinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&device_type=${device}`
      );
      const result = await response.json();
      setDataHardwareTile(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const HardwareTileInfoNumberData  = async (filterValue,device) => {
    try {

    const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/hardwarecharttileinfov1`
      );
      const result = await response.json();
      sethardwarenumberdata(result)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    HardwareTileInfoNumberData()
  },);







  const CraftedTileInfo = async (filterValue) => {
    try {
      let startTime = 0;
      let endTime = 0;
      if (craftedselectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(craftedstartDate);
        endTime = dateToTimestampInSeconds(craftedendDate);
      }

      let ageStart = 0;
      let ageEnd = timestampInSeconds; // Default to current timestamp if no age filter is applied

      if (craftedselectedAgeOption.value !== "0") {
        // Age filter selected
        const [startAge, endAge] = craftedselectedAgeOption.value.split("-");
        // console.log(startAge, endAge);
        ageStart = calculateTimestampForAge(parseInt(startAge));
        ageEnd = calculateTimestampForAge(parseInt(endAge));
        // console.log(ageStart, ageEnd);
      }

      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardcraftedtileinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&table=StatUserEventMake&agestart=${ageStart}&ageend=${ageEnd}`
      );
      const result = await response.json();
      setDataCraftedTile(result.dateswise);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ActiveTileInfo = async (filterValue) => {
    try {
      let startTime = 0;
      let endTime = 0;
      if (activeselectedDateOption.value === 3) {
        // Custom Date Range
        startTime = dateToTimestampInSeconds(activestartDate);
        endTime = dateToTimestampInSeconds(activeendDate);
      }

      let ageStart = 0;
      let ageEnd = 0; // Default to current timestamp if no age filter is applied

      if (activeselectedAgeOption.value !== "0") {
        // Age filter selected
        const [startAge, endAge] = activeselectedAgeOption.value.split("-");
        ageStart = calculateTimestampForAge(parseInt(startAge));
        ageEnd = calculateTimestampForAge(parseInt(endAge));
        // console.log(ageStart, ageEnd);
      }

      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/dashboardactiveusertileinfo?startTime=${startTime}&endTime=${endTime}&filter=${filterValue}&agestart=${ageStart}&ageend=${ageEnd}`
      );
      const result = await response.json();
      setDataActiveTile(result.dateswise);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const ProductTileInfo = async (filterValue) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(
  //       `https://api.barsys.ai/barsysanalytics/public/api/dashboardproducttileinfov2?startTime=0&endTime=0&datefilter=0`
  //     );
  //     return await response.json();
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setIsLoading(false); // Ensures loading is turned off whether fetch succeeds or fails
  //   }
  // };

  // const ReceipesTileInfo = async (page = 1, filterValue = 0) => {
  //   try {
  //     const response = await fetch(
  //       `https://api.barsys.ai/barsysanalytics/public/api/dashboardnewrecipetileinfov2?startTime=0&endTime=0&filter=0&page=${page}`
  //     );
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  //     return await response.json();
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     throw error; // Propagate error to be caught in the calling function
  //   }
  // };

  // const MixlistTileInfo = async (filterValue) => {
  //   try {
  //     const response = await fetch(
  //       `https://api.barsys.ai/barsysanalytics/public/api/dashboardnewplaylisttileinfo`
  //     );
  //     return await response.json();
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleDateOptionChange = (selectedOption) => {
    setSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      // Custom Date Range option selected
      setCustomDateRange(true); // Enable custom date range
    } else {
      setCustomDateRange(false); // Disable custom date range
    }
  };

  const handleDateOptionChangeCrafted = (selectedOption) => {
    setCraftedSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setcraftedCustomDateRange(true);
    } else {
      setcraftedCustomDateRange(false);
    }
  };


  const handleDateOptionChangeHardware = (selectedOption) => {
    setHardwareSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      sethardwareCustomDateRange(true);
    } else {
      sethardwareCustomDateRange(false);
    }
  };



  const handleDateOptionChangeActive = (selectedOption) => {
    setActiveSelectedDateOption(selectedOption);
    if (selectedOption.value === 3) {
      setActiveCustomDateRange(true);
    } else {
      setActiveCustomDateRange(false);
    }
  };

  const labels = DashUserTile.map((item) => item.label);
  const barvalues = DashUserTile.map((item) => item.value);

  const craftedlabels = CraftedTile.map((item) => item.label);
  const craftedlinevalues = CraftedTile.map((item) => item.value);

  const hardwarelabels = DashHardwareTile.map((item) => item.label);
  const hardwarelinevalues = DashHardwareTile.map((item) => item.value);


  const activelabels = ActiveTile.map((item) => item.label);
  const activebarvalues = ActiveTile.map((item) => item.value);
  const activebarvaluess = ActiveTile.map((item) => item.activeUservalue);

  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var gray500 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-500")
    .trim();
  var orange = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();
  var indigo = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();

  var TRUbarChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "33%", endingShape: "rounded" },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [orange, indigo],
    stroke: { show: true, width: 3, colors: ["transparent"] },
    grid: { borderColor: borderColor },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: true,
        color: "rgba(" + orange + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var TRUbarChartData = [
    { name: "Website", data: [] },
    { name: "Apps", data: barvalues },
  ];

  var CraftedChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: themeFont,
        color: inverse,
      },
    },
    colors: ["#96E2E3", gray500],
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        opacity: 0.9,
        dropShadow: { enabled: false },
      },
    },
    stroke: { curve: "straight", width: 4 },
    grid: {
      borderColor: borderColor,
      row: {
        colors: ["rgba(" + inverseRgb + ", .25)", "transparent"],
        opacity: 0.5,
      },
    },
    markers: { size: 6 },
    xaxis: {
      categories: craftedlabels,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      // min: 5, max: 40,
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      offsetY: -10,
      horizontalAlign: "right",
      floating: true,
      fontFamily: themeFont,
      labels: { colors: inverse },
    },
  };

  var CraftedChartData = [{ name: "Cocktails", data: craftedlinevalues }];

  var HardwareChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: themeFont,
        color: inverse,
      },
    },
    colors: ["#96E2E3", gray500],
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        opacity: 0.9,
        dropShadow: { enabled: false },
      },
    },
    stroke: { curve: "straight", width: 4 },
    grid: {
      borderColor: borderColor,
      row: {
        colors: ["rgba(" + inverseRgb + ", .25)", "transparent"],
        opacity: 0.5,
      },
    },
    markers: { size: 6 },
    xaxis: {
      categories: hardwarelabels,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      // min: 5, max: 40,
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      offsetY: -10,
      horizontalAlign: "right",
      floating: true,
      fontFamily: themeFont,
      labels: { colors: inverse },
    },
  };


  var HardwareChartData = [{ name: "Devices", data: hardwarelinevalues }];




  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var NewUserChartOptions = {
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: themeFont,
        color: inverse,
      },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" },
    },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ["transparent"] },
    colors: [themeColor, "#7897FF", "#B5B5B5"],
    grid: { borderColor: borderColor },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      title: { text: "" },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    fill: { opacity: 1 },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
    tooltip: {
      y: {
        formatter: function (val) {
          return " " + val + " ";
        },
      },
    },
  };

  var NewUserChartData = [
    { name: "New Users", data: [44, 55, 57, 56, 61, 58, 63, 60, 66] },
    { name: "Active Users", data: [76, 85, 101, 98, 87, 105, 91, 114, 94] },
    { name: "Non-Active Users", data: [35, 41, 36, 26, 45, 48, 52, 53, 41] },
  ];



  var AUbarChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "33%", endingShape: "rounded" },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [orange, indigo],
    stroke: { show: true, width: 3, colors: ["transparent"] },
    grid: { borderColor: borderColor },
    xaxis: {
      categories: activelabels,
      axisBorder: {
        show: true,
        color: "rgba(" + orange + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };
  var AUbarChartOptionsmain = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: { show: true },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "33%", endingShape: "rounded" },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: { fontSize: "12px", colors: ["#ffffff"] },
    },
    colors: [orange, "gray"],
    stroke: { show: true, width: 3, colors: ["transparent"] },
    grid: { borderColor: borderColor },
    xaxis: {
      categories: activelabels,
      axisBorder: {
        show: true,
        color: "rgba(" + orange + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: false,
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "",
        },
      },
    },
    legend: { fontFamily: themeFont, labels: { colors: inverse } },
  };

  var AUbarChartData = [
    { name: "New User", data: activebarvalues },
    {
      name: "Active User",
      data: [18, 21, 9, 22, 17, 11, 33, 19, 16, 8, 25, 23],
    },
  ];

  //Expand Data Modal
  const TotalUserExpand = (
    <>
      <h3>TOTAL REGISTERED USER</h3>
      <div className="row">
        {data && data.userActive && (
          <h2 className="col-xl-3">
            {data.userActive}
            {/* 1.98 M */}
          </h2>
        )}

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={dateOptions}
            value={selectedDateOption}
            onChange={handleDateOptionChange}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {customDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleChangeStart}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={endDate}
                  onChange={handleChangeEnd}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select
            options={AgeOptions}
            value={selectedAgeOption}
            onChange={handleAgeOptionChange}
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="bar"
          options={TRUbarChartOptions}
          series={TRUbarChartData}
          height="600"
        />
      </div>

      {data && (
        <h5>
        {data2 && data2.userPreviuseWeek > 0 ? (
    data2.userWeek !== data2.userPreviuseWeek ? (
      <>
        <i
          className={`fas ${
            data2.userWeek > data2.userPreviuseWeek
              ? "fa-angle-up"
              : "fa-angle-down"
          }`}
        ></i>
        &nbsp;
        {Math.abs(
          ((data2.userWeek - data2.userPreviuseWeek) /
            data2.userPreviuseWeek) *
            100
        ).toFixed(2)}{" "}
        %{" "}
        {data2.userWeek > data2.userPreviuseWeek
          ? "more"
          : "less"}{" "}
        than last week
      </>
    ) : (
      "No change from last week"
    )
  ) : (
    "Data unavailable"
  )}
        </h5>
      )}
      {data && (
        <h5>
          <i className="fas fa-user"></i> {data.userlastWeekUser} new visitors
        </h5>
      )}
    </>
  );
  const CraftedCocktailExpand = (
    <>
      <h3>CRAFTED COCKTAILS</h3>
      <div className="row">
        {data && <h2 className="col-xl-3">{data.make}</h2>}

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={dateOptions}
            value={craftedselectedDateOption}
            onChange={handleDateOptionChangeCrafted}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {craftedcustomDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={craftedstartDate}
                  onChange={handleChangeStartCrafted}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={craftedendDate}
                  onChange={handleChangeEndCrafted}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select
            options={AgeOptions}
            value={craftedselectedAgeOption}
            onChange={handleAgeOptionChangeCrafted}
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="line"
          options={CraftedChartOptions}
          series={CraftedChartData}
          height={600}
        />
      </div>

      {/* {data && (
        <h5>
          <i className="fas fa-angle-up"></i>
          {data.makeMoreThenPrevWeek.toFixed(2)}% more that last week
        </h5>
      )} */}
      {data && (
        <h5>
          <i className="fas fa-user"></i> {data.lastWeekMake} new crafted
        </h5>
      )}
    </>
  );
  const NewUserExpand = (
    <>
      <h3>NEW USER</h3>
      <div className="row">
        <h2 className="col-xl-3">{2193}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select options={dateOptions} classNamePrefix="react-select" />
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select options={AgeOptions} classNamePrefix="react-select" />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="bar"
          options={NewUserChartOptions}
          height={600}
          series={NewUserChartData}
        />
      </div>

      <h5>
        <i className="fas fa-angle-up"></i> {"NA"}% more that last week
      </h5>
      <h5>
        <i className="fas fa-user"></i> {"NA"} new visitors
      </h5>
    </>
  );
  const HardwareExpand = (
    <>
      <h3>HARDWARE DEPLOYED</h3>
      <div className="row">
        <h2 className="col-xl-3">{hardwarebumberdata.totalDevices}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={dateOptions}
            value={hardwareselectedDateOption}
            onChange={handleDateOptionChangeHardware}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {hardwarecustomDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={craftedstartDate}
                  onChange={handleChangeStartHardware}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={craftedendDate}
                  onChange={handleChangeEndHardware}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="date">Device</label>
          <Select
            options={DeviceOptions}
            value={hardwaredeviceOption}
            onChange={handleDeviceOptionChangeHardware}
            classNamePrefix="react-select"
          />
         
        </div>

        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="line"
          options={HardwareChartOptions}
          series={HardwareChartData}
          height={600}
        />
      </div>

      <h5>
      {hardwarebumberdata.previuseWeek > 0 ? (
    hardwarebumberdata.currentWeek !== hardwarebumberdata.previuseWeek ? (
      <>
        <i
          className={`fas ${
            hardwarebumberdata.currentWeek > hardwarebumberdata.previuseWeek
              ? "fa-angle-up"
              : "fa-angle-down"
          }`}
        ></i>{" "}
        {Math.abs(
          ((hardwarebumberdata.currentWeek - hardwarebumberdata.previuseWeek) /
            hardwarebumberdata.previuseWeek) *
            100
        ).toFixed(2)}{" "}
        %{" "}
        {hardwarebumberdata.currentWeek > hardwarebumberdata.previuseWeek
          ? "more"
          : "less"}{" "}
        than last week
      </>
    ) : (
      "No change from last week"
    )
  ) : (
    "Data unavailable"
  )}
      </h5>
      <h5>
        <i className="fas fa-user"></i> {hardwarebumberdata.currentWeek} unique visitors
      </h5>
    </>
  );
  const ActiveUserExpand = (
    <>
      <h3>ACTIVE USER</h3>
      <div className="row">
        <h2 className="col-xl-2">{18463}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={dateOptions}
            value={activeselectedDateOption}
            onChange={handleDateOptionChangeActive}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {activecustomDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={activestartDate}
                  onChange={handleChangeStartActive}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={activeendDate}
                  onChange={handleChangeEndActive}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select
            options={AgeOptions}
            value={activeselectedAgeOption}
            onChange={handleAgeOptionChangeActive}
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2">
          <label htmlFor="location">Users</label>
          <Select options={userOptions} classNamePrefix="react-select" />
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="row align-items-center mb-1">
        {/* <div className="col-6 text-right mb-1">
                  <h3 className="mb-0">{500}</h3>
                </div> */}
        <div className="col-10 text-end mt-4">
          <h2 className="mb-0" style={{ color: "#3CD25D" }}>
            {"0"}
          </h2>
          <p className="mb-0" style={{ color: "gray" }}>
            {"New Active User"}
          </p>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        <Chart
          type="bar"
          options={AUbarChartOptions}
          series={AUbarChartData}
          height={600}
        />
      </div>

      <h5>
        <i className="fas fa-angle-up"></i> {"NA"}% more active user than last
        week
      </h5>
    </>
  );
  const GeographicalExpand = (
    <>
      {/* <h3>GEOGRAPHICAL LOCATION</h3>
      <div className="row">
        <h2 className="col-xl-2">{0}</h2>

        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select options={dateOptions} classNamePrefix="react-select" />
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Age</label>
          <Select options={AgeOptions} classNamePrefix="react-select" />
        </div>
        <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div>

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="ratio ratio-21x9 mb-3">
        <div id="world-map" className="jvectormap-without-padding"></div>
      </div> */}
      <div className="row gx-4">
        <div className="col-lg-12 mb-3  mb-lg-0">
          <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Active User</th>
                <th>Cocktail Crafted</th>
              </tr>
              <tr className="text-inverse text-opacity-75">
                <th className="w-50 ">COUNTRY</th>
                <th className="w-25">
                  <div className="w-100">
                    <label className="w-50">Count</label>
                    <label className="w-50">PCT%</label>
                  </div>
                </th>
                <th className="w-25">
                  <div className="w-100">
                    <label className="w-50">Count</label>
                    <label className="w-50">PCT%</label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {countryData && countryData.length > 0 ? (
                    countryData.slice(0, 3).map((country, index) => (
                      <tr key={index} className={country.class}>
                        <td>{country.name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  )}
                </td>
                <td>
                  {countryData && countryData.length > 0 ? (
                    countryData.slice(0, 3).map((country, index) => (
                      <div key={index} className={country.class}>
                        <label className="w-50">{country.visits}</label>
                        <label className="w-50">{country.pct}</label>
                      </div>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  )}
                </td>
                <td>
                  {countryData && countryData.length > 0 ? (
                    countryData.slice(0, 3).map((country, index) => (
                      <div key={index} className={country.class}>
                        <label className="w-50">{country.ccvisits}</label>
                        <label className="w-50">{country.ccpct}</label>
                      </div>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No records found</td>
                    </tr>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className="col-lg-6">
                  <Card>
                    <CardBody className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="w-70px">
                          {sourceChartData && sourceChartData.length > 0 && (
                            <Chart
                              type="donut"
                              height="70"
                              options={chartOptions["donut"]}
                              series={sourceChartData}
                            />
                          )}
                        </div>
                        <div className="flex-1 ps-2">
                          <table className="w-100 small mb-0 text-inverse text-opacity-60">
                            <tbody>
                              {sourceData && sourceData.length > 0 ? (
                                sourceData.map((source, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className={
                                            "w-6px h-6px rounded-pill me-2 " +
                                            source.class
                                          }
                                        ></div>
                                        {source.name}
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      {source.percentage}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2">No records found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div> */}
      </div>
    </>
  );

  const TopProductsExpand = (
    <>
      <h3>TOP PRODUCTS</h3>
      <div className="row">
        <div className="col-md-3 ">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="date">Recipe Type</label>
            </div>
            <div className="col-md-7">
              <Select
                options={RecipeTypeOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="age">Sort By</label>
            </div>
            <div className="col-md-7">
              <Select options={sortOptions}  value={Topproductsortbyoption}
            onChange={handleSortOptionChangeTopProduct}  classNamePrefix="react-select" />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-md-6">
              <Select
                options={locationOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-2">
          <div class="row">
            
            <div className="col-md-12">
              <Form.Control
               type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search input
            placeholder="Search"
              />
            </div>
          </div>
        </div>

        <div className="col-md-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="col-12 text-start mt-4 mb-3">
        <h2 className="mb-0" style={{ color: "#3CD25D" }}>
          {" "}
        </h2>
        <p className="mb-0" style={{ color: "gray" }}>
          {" "}
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 ">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {producttabsEX.map(
            (tab, index) => (
              <li className="nav-item" key={tab.id}>
                <button
                  className={`nav-link ${topProductactiveTab === tab.id ? "active" : ""}`}
                  onClick={() => handleTabClickTopProduct(tab.id)}
                >
                  {tab.label}
                </button>
              </li>
            )
          )}
            </ul>
            <div className="tab-content mt-3">
            {TPtabContentEX[topProductactiveTab]}
        </div>
          </div>
        </div>
      <div className="row mt-3">
        <div className="col-md-3 offset-md-9">
          <Paginationpagewise
            currentPage={currentPage}
            itemsPerPage={itemsPerPageNewRecipe}
            totalItems={totalItems}
            paginate={handlePageClickTopProduct}
          />
        </div>
      </div>
      </div>
    </>
  );

  const ReceipesProductsExpand = (
    <>
      <h3>NEW RECIPES</h3>
      <div className="row">
        <div className="col-md-4 ">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="date">Recipe Type</label>
            </div>
            <div className="col-md-7">
              <Select
                options={RecipeTypeOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="age">Sort By</label>
            </div>
            <div className="col-md-7">
              <Select options={sortOptions} classNamePrefix="react-select" />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-md-6">
              <Select
                options={locationOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        {/* <div className="col-md-2">
          <div class="row">
            <div class="col-md-3">
              <label htmlFor="users">Users</label>
            </div>
            <div class="col-md-6">
              <Form.Select id="users">
                <option value="app">App</option>
                <option value="website">Website</option>
                <option value="app_device">App + Device</option>
                <option value="website_device">Website + Device</option>
                <option value="subscribers">Subscribers</option>
              </Form.Select>
            </div>
          </div>
        </div> */}

        <div className="col-md-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="col-12 text-start mt-4 mb-3">
        <h2 className="mb-0" style={{ color: "#3CD25D" }}>
          {" "}
        </h2>
        <p className="mb-0" style={{ color: "gray" }}>
          {" "}
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 ">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
            {newreceipestabsEX.map(
            (tab, index) => (
              <li className="nav-item" key={tab.id}>
                <button
                  className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.label}
                </button>
              </li>
            )
          )}
            </ul>

            <div className="tab-content mt-3">
            {ReceipestabContentEX[activeTab]}
        </div>


            {/* <div className="tab-content" id="myTabContent">
              {newreceipestabsEX.map((tab, index) => (
                <div
                  className={`tab-pane fade ${tab.active ? "show active" : ""}`}
                  id={tab.id}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  key={index}
                >
                  {ReceipestabContentEX[activeTab]}
                </div>
              ))}
            </div> */}
          </div>
        </div>

        <div className="row mt-3">
        <div className="col-md-3 offset-md-9">
          <Paginationpagewise
            currentPage={currentPage}
            itemsPerPage={itemsPerPageNewRecipe}
            totalItems={totalItems}
            paginate={handlePageClick}
          />
        </div>
      </div>
      
  
    </div>
    </>
  );

  const NewMixlistExpand = (
    <>
      <h3>New Mixlist</h3>
      <div className="row">
        <div className="col-md-4 ">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="date">Recipe Type</label>
            </div>
            <div className="col-md-7">
              <Select
                options={RecipeTypeOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="age">Sort By</label>
            </div>
            <div className="col-md-7">
              <Select options={sortOptions} classNamePrefix="react-select" />
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="location">Location</label>
            </div>
            <div className="col-md-6">
              <Select
                options={locationOptions}
                classNamePrefix="react-select"
              />
            </div>
          </div>
        </div>

        <div className="col-md-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div className="col-12 text-start mt-4 mb-3">
        <h2 className="mb-0" style={{ color: "#3CD25D" }}>
          {" "}
        </h2>
        <p className="mb-0" style={{ color: "gray" }}>
          {" "}
        </p>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 ">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {newmixlisttabsEX.map(
            (tab, index) => (
              <li className="nav-item" key={tab.id}>
                <button
                  className={`nav-link ${topMixlistactiveTab === tab.id ? "active" : ""}`}
                  onClick={() => handleTabClickMixlist(tab.id)}
                >
                  {tab.label}
                </button>
              </li>
            )
          )}
            </ul>
            <div className="tab-content mt-3">
            {MixlistcontentEX[topMixlistactiveTab]}
        </div>
          </div>
        </div>
      <div className="row mt-3">
        <div className="col-md-3 offset-md-9">
          <Paginationpagewise
            currentPage={currentPage}
            itemsPerPage={itemsPerPageNewRecipe}
            totalItems={totalItems}
            paginate={handlePageClickMixlist}
          />
        </div>
      </div>
      </div>
    </>
  );
  renderMap();
  return (
    <>
      <div className="row">
        {/* Total Registered User- Start*/}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small">
                <h6 className="flex-grow-1">{"TOTAL REGISTERED USERS"}</h6>
                <ModalComponent cardBodyContent={TotalUserExpand} />
              </div>
              <div className="row align-items-center">
                <div className="col-5 mt-3 mb-3">
                  <h3 className="mb-0">
                    {data && data.userActive ? (
                      data.userActive
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )}
                    {/* 1.98 M */}
                  </h3>
                </div>
                {/* <div className="col-7 mt-3 mb-3"> */}
                <div className="col-7 mt-3">
                  {statsData &&
                    statsData.length > 0 &&
                    statsData
                      .filter((stat, index) => index === 0)
                      .map((stat, index) => (
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={TRUbarChartData}
                          key={index}
                        />
                      ))}
                </div>
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate">
              {data2 && data2.userPreviuseWeek > 0 ? (
    data2.userWeek !== data2.userPreviuseWeek ? (
      <>
        <i
          className={`fas ${
            data2.userWeek > data2.userPreviuseWeek
              ? "fa-angle-up"
              : "fa-angle-down"
          }`}
        ></i>
        &nbsp;
        {Math.abs(
          ((data2.userWeek - data2.userPreviuseWeek) /
            data2.userPreviuseWeek) *
            100
        ).toFixed(2)}{" "}
        %{" "}
        {data2.userWeek > data2.userPreviuseWeek
          ? "more"
          : "less"}{" "}
        than last week
      </>
    ) : (
      "No change from last week"
    )
  ) : (
    "Data unavailable"
  )}
                
                <br></br>
                <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                new visitors
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 33.89% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Total Registered User - End*/}
        {/* Crafted Cocktailes - Start*/}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h6 className="flex-grow-1">{"CRAFTED COCKTAILS"}</h6>
                <ModalComponent cardBodyContent={CraftedCocktailExpand} />
              </div>
              <div className="row align-items-center mb-1">
                <div className="col-12 text-center mb-2">
                  <h3 className="mb-0">
                    {data && data.make ? (
                      data.make
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )}
                    {/* 961447 */}
                  </h3>
                </div>
                {/* <div className="col-12 text-center">
                  <h6 className="mb-0" style={{ color: "#3CD25D" }}>
                    {data && data.makeMoreThenPrevWeek
                      ? data.makeMoreThenPrevWeek.toFixed(2) + "%"
                      : "Loading..."}
                  </h6>
                  <p className="mb-0" style={{ color: "#3CD25D" }}>
                    {"New Crafted Cocktails"}
                  </p>
                </div> */}

                {/* <div className="col-12 text-end mt-1">
                  <h6 className="mb-0" style={{ color: "gray" }}>
                    {"Last 7d"}
                  </h6>
                </div> */}
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate mt-2">
              {data2 && data2.newMakepreviuseWeek > 0 ? (
    data2.newMakeWeek !== data2.newMakepreviuseWeek ? (
      <>
        <i
          className={`fas ${
            data2.newMakeWeek > data2.newMakepreviuseWeek
              ? "fa-angle-up"
              : "fa-angle-down"
          }`}
        ></i>{" "}
        {Math.abs(
          ((data2.newMakeWeek - data2.newMakepreviuseWeek) /
            data2.newMakepreviuseWeek) *
            100
        ).toFixed(2)}{" "}
        %{" "}
        {data2.newMakeWeek > data2.newMakepreviuseWeek
          ? "more"
          : "less"}{" "}
        than last week
      </>
    ) : (
      "No change from last week"
    )
  ) : (
    "Data unavailable"
  )}
                <br></br>
                <i className="fas fa-glass-martini"></i>{" "}
                {data && data.lastWeekMake} new crafted cocktails
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 43.66% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Crafted Cocktailes - End*/}
        {/* New Users - Start */}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h6 className="flex-grow-1">{"NEW USERS"}</h6>
                <ModalComponent cardBodyContent={NewUserExpand} />
              </div>
              <div className="row align-items-center mb-1">
                <div className="col-12 text-center mb-2">
                  <h3 className="mb-0">
                    {/* {data && data.userActive ? (
                      data.userActive
                    ) : (
                      <div className="spinner-border text-success"></div>
                    )} */}
                    NA
                  </h3>
                </div>
                {/* <div className="col-12 text-center">
                  <h6 className="mb-0" style={{ color: "#3CD25D" }}>
                    {data && data.makeMoreThenPrevWeek
                      ? data.makeMoreThenPrevWeek.toFixed(2) + "%"
                      : "Loading..."}
                  </h6>
                  <p className="mb-0" style={{ color: "#3CD25D" }}>
                    {"New Crafted Cocktails"}
                  </p>
                </div> */}

                {/* <div className="col-12 text-end mt-1">
                  <h6 className="mb-0" style={{ color: "gray" }}>
                    {"Last 7d"}
                  </h6>
                </div> */}
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate mt-2">
                <i className="fas fa-angle-up"></i> NA% more than last week{" "}
                <br></br>
                <i className="fas fa-user"></i> NA new user
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 18.96% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* New Users - End */}
        {/* Hardware Deployed - Start */}
        <div className="col-xl-3 col-lg-6">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small">
                <h6 className="flex-grow-1">{"HARDWARE DEPLOYED"}</h6>
                <ModalComponent cardBodyContent={HardwareExpand} />
              </div>
              <div className="row align-items-center">
                <div className="col-4 mt-3 mb-3">
                  <h3 className="mb-0">{hardwarebumberdata.totalDevices}</h3>
                </div>
                <div className="col-8 mt-3 mb-3">
                  {statsData &&
                    statsData.length > 0 &&
                    statsData
                      .filter((stat, index) => index === 1)
                      .map((stat, index) => (
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={HardwareChartData}
                          key={index}
                        />
                      ))}
                </div>
              </div>
              <div className="small text-inverse text-opacity-50 text-truncate">
              {hardwarebumberdata.previuseWeek > 0 ? (
    hardwarebumberdata.currentWeek !== hardwarebumberdata.previuseWeek ? (
      <>
        <i
          className={`fas ${
            hardwarebumberdata.currentWeek > hardwarebumberdata.previuseWeek
              ? "fa-angle-up"
              : "fa-angle-down"
          }`}
        ></i>{" "}
        {Math.abs(
          ((hardwarebumberdata.currentWeek - hardwarebumberdata.previuseWeek) /
            hardwarebumberdata.previuseWeek) *
            100
        ).toFixed(2)}{" "}
        %{" "}
        {hardwarebumberdata.currentWeek > hardwarebumberdata.previuseWeek
          ? "more"
          : "less"}{" "}
        than last week
      </>
    ) : (
      "No change from last week"
    )
  ) : (
    "Data unavailable"
  )}
                <br></br>
                <i className="fas fa-microchip"></i> {hardwarebumberdata.currentWeek} new hardware deployed this week
                <br></br>
                {/* <i className="fas fa-chart-line"></i> 36% bounce rate */}
                {/* {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))} */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Hardware Deployed - End */}

        {/* {statsData &&
          statsData.length > 0 &&
          statsData.map((stat, index) => (
            <div className="col-xl-3 col-lg-6" key={index}>
              <Card className="mb-3">
                <CardBody>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">{stat.title}</span>
                    <CardExpandToggler />
                  </div>
                  <div className="row align-items-center mb-2">
                    <div className="col-7">
                      <h3 className="mb-0">{stat.total}</h3>
                    </div>
                    <div className="col-5">
                      <div className="mt-n2">
                        <Chart
                          type={stat.chartType}
                          height={stat.chartHeight}
                          options={chartOptions[stat.chartType]}
                          series={stat.chartData}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="small text-inverse text-opacity-50 text-truncate">
                    {stat.info.length > 0 &&
                      stat.info.map((info, index) => (
                        <div key={index}>
                          <i className={info.icon}></i> {info.text}
                        </div>
                      ))}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))} */}
        {/* Active User -- Starts */}

        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">ACTIVE USER</h5>
                <ModalComponent cardBodyContent={ActiveUserExpand} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h3 className="mb-0">
                    {data && data.allActiveUser ? data.allActiveUser : 0}
                    {/* 18463 */}
                  </h3>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0}
                    {/* 96 */}
                  </h5>
                  <p className="mb-0" style={{ color: "gray" }}>
                    {"New Active User"}
                  </p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                <Chart
                  type="bar"
                  height="100%"
                  options={AUbarChartOptionsmain}
                  series={AUbarChartData}
                />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0}
                % more active user than last week
              </div>
              {/* <div className="row">
                {serverData && serverData.length > 0 ? (
                  serverData.map((server, index) => (
                    <div className="col-lg-6 mb-3 mb-lg-0" key={index}>
                      <div className="d-flex">
                        <div className="w-50px pt-3">
                          <Chart
                            type={server.chartType}
                            height={server.chartHeight}
                            options={chartOptions[server.chartType]}
                            series={server.chartData}
                          />
                        </div>
                        <div className="ps-3 flex-1">
                          <div className="fs-10px fw-bold text-inverse text-opacity-50 mb-1">
                            {server.name}
                          </div>
                          <div className="mb-2 fs-5 text-truncate">
                            {server.total}
                          </div>
                          <div className="progress h-3px bg-inverse-transparent-2 mb-1">
                            <div
                              className="progress-bar bg-theme"
                              style={{ width: server.progress }}
                            ></div>
                          </div>
                          <div className="fs-11px text-inverse text-opacity-50 mb-2 text-truncate">
                            {server.time}
                          </div>
                          {server.info.length > 0 ? (
                            server.info.map((info, index) => (
                              <div
                                className="d-flex align-items-center small"
                                key={index}
                              >
                                <i
                                  className={
                                    "bi bi-circle-fill fs-6px me-2 " +
                                    info.class
                                  }
                                ></i>
                                <div className="flex-1">{info.title}</div>
                                <div>{info.value}</div>
                              </div>
                            ))
                          ) : (
                            <div>No records found</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12">No records found</div>
                )}
              </div> */}
            </CardBody>
          </Card>
        </div>
        {/* Active User -- Ends */}
        {/* Geographical User -- Starts */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">GEOGRAPHICAL LOCATION</h5>
                <Geomapexpand cardBodyContent={GeographicalExpand} />
              </div>
              <div className="ratio ratio-21x9 mb-4">
                <div
                  id="world-map"
                  className="jvectormap-without-padding"
                ></div>
              </div>
              <div className="row gx-4">
                <div className="col-lg-12 mb-3  mb-lg-0">
                  <table className="w-100 small mb-0 text-truncate text-inverse text-opacity-60">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Active User</th>
                        <th>Cocktail Crafted</th>
                      </tr>
                      <tr className="text-inverse text-opacity-75">
                        <th className="w-50 ">COUNTRY</th>
                        <th className="w-25">
                          <div className="w-100">
                            <label className="w-50">Count</label>
                            <label className="w-50">PCT%</label>
                          </div>
                        </th>
                        <th className="w-25">
                          <div className="w-100">
                            <label className="w-50">Count</label>
                            <label className="w-50">PCT%</label>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <tr key={index} className={country.class}>
                                <td>{country.name}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <div key={index} className={country.class}>
                                <label className="w-50">{country.visits}</label>
                                <label className="w-50">{country.pct}</label>
                              </div>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                        <td>
                          {countryData && countryData.length > 0 ? (
                            countryData.slice(0, 3).map((country, index) => (
                              <div key={index} className={country.class}>
                                <label className="w-50">
                                  {country.ccvisits}
                                </label>
                                <label className="w-50">{country.ccpct}</label>
                              </div>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No records found</td>
                            </tr>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* <div className="col-lg-6">
                  <Card>
                    <CardBody className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="w-70px">
                          {sourceChartData && sourceChartData.length > 0 && (
                            <Chart
                              type="donut"
                              height="70"
                              options={chartOptions["donut"]}
                              series={sourceChartData}
                            />
                          )}
                        </div>
                        <div className="flex-1 ps-2">
                          <table className="w-100 small mb-0 text-inverse text-opacity-60">
                            <tbody>
                              {sourceData && sourceData.length > 0 ? (
                                sourceData.map((source, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div
                                          className={
                                            "w-6px h-6px rounded-pill me-2 " +
                                            source.class
                                          }
                                        ></div>
                                        {source.name}
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      {source.percentage}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2">No records found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Geographical User -- Ends */}
        {/* Top Products -- Starts */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">TOP PRODUCTS</h5>
                <ModalComponent cardBodyContent={TopProductsExpand} />
              </div>

              {/* <div className="col-12 text-end">
                <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                  {"50"}
                </h5>
                <p className="mb-0" style={{ color: "gray" }}>
                  {"New Active User"}
                </p>
              </div> */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 ">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {producttabs.map((tab, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <button
                            className={`nav-link ${tab.active ? "active" : ""}`}
                            id={`${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={tab.active ? "true" : "false"}
                          >
                            {tab.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {producttabs.map((tab, index) => (
                        <div
                          className={`tab-pane fade ${
                            tab.active ? "show active" : ""
                          }`}
                          id={tab.id}
                          role="tabpanel"
                          aria-labelledby={`${tab.id}-tab`}
                          key={index}
                        >
                          {TPtabContent[index]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* Top Products -- Ends */}
        {/* New Receipes -- Start*/}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">NEW RECIPES</h5>
                <ModalComponent cardBodyContent={ReceipesProductsExpand} />
              </div>

              {/* <div className="col-12 text-end">
                <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                  {"50"}
                </h5>
                <p className="mb-0" style={{ color: "gray" }}>
                  {"New Active User"}
                </p>
              </div> */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 ">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {newreceipestabs.map((tab, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <button
                            className={`nav-link ${tab.active ? "active" : ""}`}
                            id={`${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={tab.active ? "true" : "false"}
                          >
                            {tab.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {newreceipestabs.map((tab, index) => (
                        <div
                          className={`tab-pane fade ${
                            tab.active ? "show active" : ""
                          }`}
                          id={tab.id}
                          role="tabpanel"
                          aria-labelledby={`${tab.id}-tab`}
                          key={index}
                        >
                          {ReceipestabContent[index]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* New Receipes -- End*/}
        {/* New Mixlist-- Start*/}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h5 className="flex-grow-1">NEW MIXLIST</h5>
                <ModalComponent cardBodyContent={NewMixlistExpand} />
              </div>

              {/* <div className="col-12 text-end">
                <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                  {"50"}
                </h5>
                <p className="mb-0" style={{ color: "gray" }}>
                  {"New Active User"}
                </p>
              </div> */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 ">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {newmixlisttabs.map((tab, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <button
                            className={`nav-link ${tab.active ? "active" : ""}`}
                            id={`${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={tab.id}
                            aria-selected={tab.active ? "true" : "false"}
                          >
                            {tab.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {newmixlisttabs.map((tab, index) => (
                        <div
                          className={`tab-pane fade ${
                            tab.active ? "show active" : ""
                          }`}
                          id={tab.id}
                          role="tabpanel"
                          aria-labelledby={`${tab.id}-tab`}
                          key={index}
                        >
                          {Mixlistcontent[index]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* New Mixlist -- End */}
      </div>
    </>
  );
}

export default Dashboard;
