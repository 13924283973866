import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Card, CardBody } from "./../../components/card/card.jsx";
import Paginationpagewise from "./../../components/pagination/Paginationpagewise.jsx";
import glassshimmer from "../../assets/glassshimmer.png";

const Allrecipe = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [allRecipes, setAllRecipes] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  // Fetch Data Based on Search or Default API
  const fetchRecipes = async (page, search = "") => {
    setIsLoading(true);
    setIsSearching(!!search);

    try {
      let apiURL = `https://api.barsys.ai/barsysanalytics/public/api/allRecipesData?page=${page}`;

      if (search.trim() !== "") {
        apiURL = `https://api.barsys.ai/barsysanalytics/public/api/allRecipesDataSearch?searchterm=${search}&page=${page}`;
      }

      console.log("Fetching data from API:", apiURL); // Debugging

      const response = await fetch(apiURL);
      const result = await response.json();

      console.log("API Response:", result); // Debugging

      if (Array.isArray(result)) {
        setAllRecipes(result);
        setTotalItems(result.length);
      } else {
        setAllRecipes([]);
        setTotalItems(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAllRecipes([]);
      setTotalItems(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Data when Page Changes
  useEffect(() => {
    fetchRecipes(currentPage, searchTerm);
  }, [currentPage]);

  // Handle Search API with Debounce
  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setCurrentPage(1);
      fetchRecipes(1, searchTerm);
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  // Handle Search Input Change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Pagination Function
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchRecipes(pageNumber, searchTerm);
  };

  return (
    <>
      <div>
        <h6>
          <span style={{ color: "#3cd2a5" }}>PAGES</span> / ALL RECIPE
        </h6>
        <h1>All Recipes</h1>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8 mt-3 pt-1">
              <Form.Control
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search"
              />
            </div>

            {/* <div className="col-md-2 mt-3 pt-1">
              <button className="btn btn-secondary" disabled>
                <i className="fas fa-search"></i>
              </button>
            </div> */}

            <div className="col-md-4 mt-4 pt-1">
  <p className="float-end">
    {totalItems === 0
      ? "0 of 0"
      : `${Math.max((currentPage - 1) * itemsPerPage + 1, 1)} - ${Math.max(
          currentPage * itemsPerPage,
          totalItems
        )}`}
  </p>
</div>

          </div>
        </div>
      </div>

      <div className="col-xl-12">
        <Card className="mt-3">
          <CardBody>
            <div className="table-responsive">
              {isLoading ? (
                <div className="spinner-border text-success"></div>
              ) : allRecipes.length === 0 ? (
                <div>No Records Found</div>
              ) : (
                allRecipes.map((product, index) => (
                  <div key={index} className="mb-3 p-3 product_border">
                    <div className="row">
                      <div className="col-xl-8 col-12 col-md-6 col">
                        <div className="d-flex align-items-center">
                          <div
                            className="rounded-2 d-flex align-items-center justify-content-center me-4 bg-theme text-theme-900"
                            style={{
                              fontWeight: "700",
                              minHeight: "20px",
                              minWidth: "20px",
                            }}
                          >
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </div>
                          <div className="position-relative">
                            <div
                              className="bg-position-center bg-size-cover bg-repeat-no-repeat w-80px h-60px"
                              style={{
                                backgroundImage: `url(${
                                  product.image ? product.image : glassshimmer
                                })`,
                              }}
                            />
                          </div>
                          <div className="flex-1 ps-3">
                            <div className="fw-500 text-inverse">
                              {product.name}
                            </div>
                            <div>Crafted By: {product.brandName}</div>
                          </div>
                          <div className="flex-1 ms-4">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="pe-2">Views:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                    {product.viewCount}
                                  </td>
                                  <td className="pe-2">Likes:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 pe-5">
                                    {product.likeCount}
                                  </td>
                                  <td className="pe-2">Crafted:</td>
                                  <td className="text-inverse text-opacity-75 fw-500 ">
                                    {product.makeCount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-12">
                        <div>
                          <label>Base:</label>
                          <span className="ms-4">{product.baseList}</span>
                        </div>
                        <div>
                          <label>Mixer:</label>
                          <span className="ms-4">{product.mixerlist}</span>
                        </div>
                        <div>
                          <label>Garnish:</label>
                          <span className="ms-2">{product.garnishlist}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </CardBody>
        </Card>
        <div className="row mt-3">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <Paginationpagewise
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Allrecipe;
