import React, { useEffect, useState, useRef } from "react";

import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody } from "./../../components/card/card.jsx";
// import Chart from "react-apexcharts";
import Chart from "chart.js/auto";
import Select from "react-select";

import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import {
  dateOptions,
  AgeOptions,
  GAUserOptions,
  GADateOptions,
  GAEcommerceOptions,
  GAEventOptions,
  GARevenueOptions,
  GASessionOptions,
  GAPageScreenOptions,
} from "./../../components/selectvalues/selectvalue.jsx";

import { ModalComponent } from "../expand/expandmodal.jsx";

const Gahomemain = () => {
  const mainChartRef = useRef(null);
  const ecommerceChartRef = useRef(null);
  const revenueChartRef = useRef(null);
  const eventChartRef = useRef(null);
  const pagescreenChartRef = useRef(null);
  const sessionChartRef = useRef(null);

  const [UserTile, setDataUserTile] = useState([]);
  const [EcommerceTile, setDataEcommerceTile] = useState([]);
  const [RevenueTile, setDataRevenueTile] = useState([]);
  const [EventTile, setDataEventTile] = useState([]);
  const [PageScreenTile, setDataPageScreenTile] = useState([]);
  const [SessionTile, setDataSessionTile] = useState([]);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [dimension, setDimension] = useState("");
  const [metric, setMetric] = useState("");
  const [agestart, setAgeStart] = useState("");
  const [ageend, setAgeEnd] = useState("");
  const [data, setData] = useState(null);

  // color & font variable
  var gray300Color = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-300")
    .trim();
  var gray300RgbColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-300-rgb")
    .trim();
  var indigoColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();
  var bodyColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-color")
    .trim();
  var bodyBg = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-bg")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();
  var bodyFontFamily = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var bodyFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();

  // chart global options
  Chart.defaults.font.family = bodyFontFamily;
  Chart.defaults.font.size = 12;
  Chart.defaults.color = bodyColor;
  Chart.defaults.borderColor = borderColor;
  Chart.defaults.plugins.legend.display = false;
  Chart.defaults.plugins.tooltip.padding = {
    left: 8,
    right: 12,
    top: 8,
    bottom: 8,
  };
  Chart.defaults.plugins.tooltip.cornerRadius = 8;
  Chart.defaults.plugins.tooltip.titleMarginBottom = 6;
  Chart.defaults.plugins.tooltip.color = bodyBg;
  Chart.defaults.plugins.tooltip.multiKeyBackground = inverse;
  Chart.defaults.plugins.tooltip.backgroundColor = "black";
  Chart.defaults.plugins.tooltip.titleFont.family = bodyFontFamily;
  Chart.defaults.plugins.tooltip.titleFont.weight = bodyFontWeight;
  Chart.defaults.plugins.tooltip.footerFont.family = bodyFontFamily;
  Chart.defaults.plugins.tooltip.displayColors = true;
  Chart.defaults.plugins.tooltip.boxPadding = 6;
  Chart.defaults.scale.grid.color = borderColor;
  Chart.defaults.scale.beginAtZero = true;
  //  Chart.defaults.maintainAspectRatio = false;

  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var gray500 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-500")
    .trim();
  var orange = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();
  var indigo = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();

  //gauserTile
  const [customDateRange, setgauserCustomDateRange] = useState(false);
  const [gauserstartDate, setgauserStartDate] = useState();
  const [gauserendDate, setgauserEndDate] = useState();

  const [gauserselectedDateOption, setgauserSelectedDateOption] = useState(
    GADateOptions[4]
  );
  const [gauserselectedOption, setgauserSelectedOption] = useState(
    GAUserOptions[0]
  );
  const [gaselectedDateOption, setgaselectedDateOption] = useState();

  const [craftedcustomDateRange, setcraftedCustomDateRange] = useState(false);
  const [craftedstartDate, setcraftedStartDate] = useState(new Date());
  const [craftedendDate, setcraftedEndDate] = useState(new Date());

  //gaecommerceTile
  const [gaecommerceselectedDateOption, setgaecommerceSelectedDateOption] =
    useState(GADateOptions[4]);
  const [gaecommerceselectedOption, setgaecommerceSelectedOption] = useState(
    GAEcommerceOptions[2]
  );

  //garevenueTile
  const [garevenueselectedDateOption, setgarevenueSelectedDateOption] =
    useState(GADateOptions[4]);
  const [garevenueselectedOption, setgarevenueSelectedOption] = useState(
    GARevenueOptions[5]
  );

  //gaeventTile
  const [gaeventselectedDateOption, setgaeventSelectedDateOption] = useState(
    GADateOptions[4]
  );
  const [gaeventselectedOption, setgaeventSelectedOption] = useState(
    GAEventOptions[1]
  );

  //gapagescreenTile
  const [gapagescreenselectedDateOption, setgapagescreenSelectedDateOption] = useState(
    GADateOptions[4]
  );
  const [gapagescreenselectedOption, setgapagescreenSelectedOption] = useState(
    GAPageScreenOptions[0]
  );


    //gasessionTile
    const [gasessionselectedDateOption, setgasessionSelectedDateOption] = useState(
        GADateOptions[4]
      );
      const [gasessionselectedOption, setgasessionSelectedOption] = useState(
        GASessionOptions[6]
      );

  const handlegauserdateChange = (selectedOption) => {
    //   setgaselectedDateOption(selectedOption);
    setgauserSelectedDateOption(selectedOption);
    //   if (selectedOption.value === 3) {
    //     setcraftedCustomDateRange(true);
    //   } else {
    //     setcraftedCustomDateRange(false);
    //   }
  };
  const handlegauserChange = (selectedOption) => {
    setgauserSelectedOption(selectedOption);
  };

  const handlegaecommercedateChange = (selectedOption) => {
    setgaecommerceSelectedDateOption(selectedOption);
  };
  const handlegaecommerceChange = (selectedOption) => {
    setgaecommerceSelectedOption(selectedOption);
  };

  const handlegarevenuedateChange = (selectedOption) => {
    setgarevenueSelectedDateOption(selectedOption);
  };
  const handlegarevenueChange = (selectedOption) => {
    setgarevenueSelectedOption(selectedOption);
  };

  const handlegaeventdateChange = (selectedOption) => {
    setgaeventSelectedDateOption(selectedOption);
  };
  const handlegaeventChange = (selectedOption) => {
    setgaeventSelectedOption(selectedOption);
  };

  const handlegapagescreendateChange = (selectedOption) => {
    setgapagescreenSelectedDateOption(selectedOption);
  };
  const handlegapagescreenChange = (selectedOption) => {
    setgapagescreenSelectedOption(selectedOption);
  };

  const handlegasessiondateChange = (selectedOption) => {
    setgasessionSelectedDateOption(selectedOption);
  };
  const handlegasessionChange = (selectedOption) => {
    setgasessionSelectedOption(selectedOption);
  };

  const craftedlabels = UserTile.dailyData
    ? UserTile.dailyData.map((item) => item.dimensionValues[0].value)
    : [];
  const craftedlinevalues = UserTile.dailyData
    ? UserTile.dailyData.map((item) => item.metricValues[0].value)
    : [];

  const ecommercelabels = EcommerceTile.dailyData
    ? EcommerceTile.dailyData.map((item) => item.dimensionValues[0].value)
    : [];
  const ecommercelinevalues = EcommerceTile.dailyData
    ? EcommerceTile.dailyData.map((item) => item.metricValues[0].value)
    : [];

  const revenuelabels = RevenueTile.dailyData
    ? RevenueTile.dailyData.map((item) => item.dimensionValues[0].value)
    : [];
  const revenuelinevalues = RevenueTile.dailyData
    ? RevenueTile.dailyData.map((item) => item.metricValues[0].value)
    : [];

  const eventlabels = EventTile.dailyData
    ? EventTile.dailyData.map((item) => item.dimensionValues[0].value)
    : [];
  const eventlinevalues = EventTile.dailyData
    ? EventTile.dailyData.map((item) => item.metricValues[0].value)
    : [];


    const pagescreenlabels = PageScreenTile.dailyData
    ? PageScreenTile.dailyData.map((item) => item.dimensionValues[0].value)
    : [];
  const pagescreenlinevalues = PageScreenTile.dailyData
    ? PageScreenTile.dailyData.map((item) => item.metricValues[0].value)
    : [];

    const sessionlabels = SessionTile.dailyData
    ? SessionTile.dailyData.map((item) => item.dimensionValues[0].value)
    : [];
  const sessionlinevalues = SessionTile.dailyData
    ? SessionTile.dailyData.map((item) => item.metricValues[0].value)
    : [];


  // console.log(pagescreenlinevalues);

  var CraftedChartData = [{ name: "Users", data: craftedlinevalues }];

  var CraftedChartOptions = {
    chart: {
      shadow: {
        enabled: true,
        color: "rgba(" + inverseRgb + ", .5)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolline: { show: true },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: themeFont,
        color: inverse,
      },
    },
    colors: ["#96E2E3", gray500],
    dataLabels: {
      enabled: false,
      background: {
        enabled: true,
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        opacity: 0.9,
        dropShadow: { enabled: false },
      },
    },
    stroke: { curve: "straight", width: 4 },
    // grid: {
    //   borderColor: borderColor,
    //   row: {
    //     colors: ["rgba(" + inverseRgb + ", .25)", "transparent"],
    //     opacity: 0.5,
    //   },
    // },
    markers: { size: 6 },
    xaxis: {
      categories: craftedlabels,
      axisBorder: {
        show: true,
        color: "rgba(" + inverseRgb + ", .25)",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: -1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "rgba(" + inverseRgb + ", .25)",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    yaxis: {
      // min: 5, max: 40,
      labels: {
        style: {
          colors: inverse,
          fontSize: "12px",
          fontFamily: themeFont,
          fontWeight: themeFontWeight,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      offsetY: -10,
      horizontalAlign: "right",
      floating: true,
      fontFamily: themeFont,
      labels: { colors: inverse },
    },
  };

  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();
  var bodyBg = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-bg")
    .trim();

  const setupChart = (
    canvasRef,
    labels,
    data,
    themeColor,
    themeColorRgb,
    bodyBg
  ) => {
    if (!canvasRef.current) return;
    return new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: gauserselectedOption.label,
            data,
            //   backgroundColor: `rgba(${themeColorRgb}, .2)`,
            borderColor: themeColor,
            borderWidth: 1.5,
            pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: themeColor,
            pointHoverBorderColor: bodyBg,
            pointHoverRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  const setupecommerceChart = (
    canvasRef,
    labels,
    data,
    themeColor,
    themeColorRgb,
    bodyBg
  ) => {
    if (!canvasRef.current) return;
    return new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: gaecommerceselectedOption.label,
            data,
            //   backgroundColor: `rgba(${themeColorRgb}, .2)`,
            borderColor: themeColor,
            borderWidth: 1.5,
            pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: themeColor,
            pointHoverBorderColor: bodyBg,
            pointHoverRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  const setuprevenueChart = (
    canvasRef,
    labels,
    data,
    themeColor,
    themeColorRgb,
    bodyBg
  ) => {
    if (!canvasRef.current) return;
    return new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: garevenueselectedOption.label,
            data,
            //   backgroundColor: `rgba(${themeColorRgb}, .2)`,
            borderColor: themeColor,
            borderWidth: 1.5,
            pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: themeColor,
            pointHoverBorderColor: bodyBg,
            pointHoverRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  const setupeventChart = (
    canvasRef,
    labels,
    data,
    themeColor,
    themeColorRgb,
    bodyBg
  ) => {
    if (!canvasRef.current) return;
    return new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: gaeventselectedOption.label,
            data,
            //   backgroundColor: `rgba(${themeColorRgb}, .2)`,
            borderColor: themeColor,
            borderWidth: 1.5,
            pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: themeColor,
            pointHoverBorderColor: bodyBg,
            pointHoverRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  const setuppagescreenChart = (
    canvasRef,
    labels,
    data,
    themeColor,
    themeColorRgb,
    bodyBg
  ) => {
    if (!canvasRef.current) return;
    return new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: gapagescreenselectedOption.label,
            data,
            //   backgroundColor: `rgba(${themeColorRgb}, .2)`,
            borderColor: themeColor,
            borderWidth: 1.5,
            pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: themeColor,
            pointHoverBorderColor: bodyBg,
            pointHoverRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  const setupsessionChart = (
    canvasRef,
    labels,
    data,
    themeColor,
    themeColorRgb,
    bodyBg
  ) => {
    if (!canvasRef.current) return;
    return new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: gasessionselectedOption.label,
            data,
            //   backgroundColor: `rgba(${themeColorRgb}, .2)`,
            borderColor: themeColor,
            borderWidth: 1.5,
            pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: themeColor,
            pointHoverBorderColor: bodyBg,
            pointHoverRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };

  useEffect(() => {
    const chartInstance = setupChart(
      mainChartRef,
      craftedlabels,
      craftedlinevalues,
      themeColor,
      themeColorRgb,
      bodyBg
    );
    return () => chartInstance && chartInstance.destroy();
  }, [craftedlabels, craftedlinevalues, themeColor, themeColorRgb, bodyBg]);

  useEffect(() => {
    const chartInstance = setupecommerceChart(
      ecommerceChartRef,
      ecommercelabels,
      ecommercelinevalues,
      themeColor,
      themeColorRgb,
      bodyBg
    );
    return () => chartInstance && chartInstance.destroy();
  }, [ecommercelabels, ecommercelinevalues, themeColor, themeColorRgb, bodyBg]);

  useEffect(() => {
    const chartInstance = setuprevenueChart(
      revenueChartRef,
      revenuelabels,
      revenuelinevalues,
      themeColor,
      themeColorRgb,
      bodyBg
    );
    return () => chartInstance && chartInstance.destroy();
  }, [revenuelabels, revenuelinevalues, themeColor, themeColorRgb, bodyBg]);

  useEffect(() => {
    const chartInstance = setupeventChart(
      eventChartRef,
      eventlabels,
      eventlinevalues,
      themeColor,
      themeColorRgb,
      bodyBg
    );
    return () => chartInstance && chartInstance.destroy();
  }, [eventlabels, eventlinevalues, themeColor, themeColorRgb, bodyBg]);


  useEffect(() => {
    const chartInstance = setuppagescreenChart(
      pagescreenChartRef,
      pagescreenlabels,
      pagescreenlinevalues,
      themeColor,
      themeColorRgb,
      bodyBg
    );
    return () => chartInstance && chartInstance.destroy();
  }, [ pagescreenlabels,pagescreenlinevalues, themeColor, themeColorRgb, bodyBg]);

  useEffect(() => {
    const chartInstance = setupsessionChart(
      sessionChartRef,
      sessionlabels,
      sessionlinevalues,
      themeColor,
      themeColorRgb,
      bodyBg
    );
    return () => chartInstance && chartInstance.destroy();
  }, [ sessionlabels,sessionlinevalues, themeColor, themeColorRgb, bodyBg]);

  const GAUserExpand = () => {
    const expandChartRef = useRef(null);

    useEffect(() => {
      const chartInstance = setupChart(
        expandChartRef,
        craftedlabels,
        craftedlinevalues,
        themeColor,
        themeColorRgb,
        bodyBg
      );
      return () => chartInstance && chartInstance.destroy();
    }, [craftedlabels, craftedlinevalues, themeColor, themeColorRgb, bodyBg]);

    return (
      <>
        <h3>GA - USER - {gauserselectedOption.label}</h3>
        <div className="row">
          {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
          <h2 className="col-xl-3">
            {UserTile.totalMetricValue
              }
          </h2>
          <div className="col-md-2">
            <label htmlFor="date">Date</label>
            <Select
              options={GADateOptions}
              value={gauserselectedDateOption}
              onChange={handlegauserdateChange}
              classNamePrefix="react-select"
            />
            <div className="col-xl-9">
              {craftedcustomDateRange && (
                <>
                  <label>From:</label>
                  <DatePicker
                    selected={craftedstartDate}
                    //   onChange={handleChangeStartCrafted}
                    className="form-control"
                  />
                  <br></br>
                  <label>To:</label>
                  <DatePicker
                    selected={craftedendDate}
                    //   onChange={handleChangeEndCrafted}
                    className="form-control"
                  />
                </>
              )}
            </div>
          </div>

          <div className="col-md-2">
            <label htmlFor="age">User Options</label>
            <Select
              options={GAUserOptions}
              value={gauserselectedOption}
              onChange={handlegauserChange}
              classNamePrefix="react-select"
            />
          </div>
          {/* <div className="col-md-2">
            <label htmlFor="location">Location</label>
            <select className="form-control" id="location">
              <option value="all">All</option>
            </select>
          </div> */}

          <div className="col-md-2 mt-3 pt-1">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="col-lg-12 mb-1 mb-lg-0">
          {/* <Chart
            type="line"
            options={CraftedChartOptions}
            series={CraftedChartData}
            height={600}
          /> */}
          <canvas ref={expandChartRef} className="w-100" height="700" />
        </div>
      </>
    );
  };

  const GAEcommerceExpand = () => {
    const expandChartRef = useRef(null);

    useEffect(() => {
      const chartInstance = setupecommerceChart(
        expandChartRef,
        ecommercelabels,
        ecommercelinevalues,
        themeColor,
        themeColorRgb,
        bodyBg
      );
      return () => chartInstance && chartInstance.destroy();
    }, [
      ecommercelabels,
      ecommercelinevalues,
      themeColor,
      themeColorRgb,
      bodyBg,
    ]);

    return (
      <>
        <h3>GA - Ecommerce -{gaecommerceselectedOption.label}</h3>
        <div className="row">
          {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
          <h2 className="col-xl-3">
            {EcommerceTile && EcommerceTile.totalMetricValue
              ? EcommerceTile.totalMetricValue
              : 0}
          </h2>
          <div className="col-md-2">
            <label htmlFor="date">Date</label>
            <Select
              options={GADateOptions}
              value={gaecommerceselectedDateOption}
              onChange={handlegaecommercedateChange}
              classNamePrefix="react-select"
            />
            <div className="col-xl-9">
              {craftedcustomDateRange && (
                <>
                  <label>From:</label>
                  <DatePicker
                    selected={craftedstartDate}
                    //   onChange={handleChangeStartCrafted}
                    className="form-control"
                  />
                  <br></br>
                  <label>To:</label>
                  <DatePicker
                    selected={craftedendDate}
                    //   onChange={handleChangeEndCrafted}
                    className="form-control"
                  />
                </>
              )}
            </div>
          </div>

          <div className="col-md-2">
            <label htmlFor="age">Ecommerce Options</label>
            <Select
              options={GAEcommerceOptions}
              value={gaecommerceselectedOption}
              onChange={handlegaecommerceChange}
              classNamePrefix="react-select"
            />
          </div>
          {/* <div className="col-md-2">
            <label htmlFor="location">Location</label>
            <select className="form-control" id="location">
              <option value="all">All</option>
            </select>
          </div> */}

          <div className="col-md-2 mt-3 pt-1">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="col-lg-12 mb-1 mb-lg-0">
          {/* <Chart
            type="line"
            options={CraftedChartOptions}
            series={CraftedChartData}
            height={600}
          /> */}
          <canvas ref={expandChartRef} className="w-100" height="700" />
        </div>
      </>
    );
  };

  const GARevenueExpand = () => {
    const expandChartRef = useRef(null);

    useEffect(() => {
      const chartInstance = setuprevenueChart(
        expandChartRef,
        revenuelabels,
        revenuelinevalues,
        themeColor,
        themeColorRgb,
        bodyBg
      );
      return () => chartInstance && chartInstance.destroy();
    }, [revenuelabels, revenuelinevalues, themeColor, themeColorRgb, bodyBg]);

    return (
      <>
        <h3>GA - Revenue - {garevenueselectedOption.label}</h3>
        <div className="row">
          {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
          <h2 className="col-xl-3">
            {RevenueTile && RevenueTile.totalMetricValue
              ? RevenueTile.totalMetricValue
              : 0}
          </h2>
          <div className="col-md-2">
            <label htmlFor="date">Date</label>
            <Select
              options={GADateOptions}
              value={garevenueselectedDateOption}
              onChange={handlegarevenuedateChange}
              classNamePrefix="react-select"
            />
            <div className="col-xl-9">
              {craftedcustomDateRange && (
                <>
                  <label>From:</label>
                  <DatePicker
                    selected={craftedstartDate}
                    //   onChange={handleChangeStartCrafted}
                    className="form-control"
                  />
                  <br></br>
                  <label>To:</label>
                  <DatePicker
                    selected={craftedendDate}
                    //   onChange={handleChangeEndCrafted}
                    className="form-control"
                  />
                </>
              )}
            </div>
          </div>

          <div className="col-md-2">
            <label htmlFor="age">Revenue Options</label>
            <Select
              options={GARevenueOptions}
              value={garevenueselectedOption}
              onChange={handlegarevenueChange}
              classNamePrefix="react-select"
            />
          </div>
          {/* <div className="col-md-2">
          <label htmlFor="location">Location</label>
          <select className="form-control" id="location">
            <option value="all">All</option>
          </select>
        </div> */}

          <div className="col-md-2 mt-3 pt-1">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="col-lg-12 mb-1 mb-lg-0">
          {/* <Chart
            type="line"
            options={CraftedChartOptions}
            series={CraftedChartData}
            height={600}
          /> */}

          <canvas ref={expandChartRef} className="w-100" height="700" />
        </div>
      </>
    );
  };

  const GAEventExpand = () => {
    const expandChartRef = useRef(null);

    useEffect(() => {
      const chartInstance = setupeventChart(
        expandChartRef,
        eventlabels,
        eventlinevalues,
        themeColor,
        themeColorRgb,
        bodyBg
      );
      return () => chartInstance && chartInstance.destroy();
    }, [eventlabels, eventlinevalues, themeColor, themeColorRgb, bodyBg]);

    return (
      <>
        <h3>GA - Event - {gaeventselectedOption.label}</h3>
        <div className="row">
          {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
          <h2 className="col-xl-3">
            {EventTile && EventTile.totalMetricValue
              ? EventTile.totalMetricValue
              : 0}
          </h2>
          <div className="col-md-2">
            <label htmlFor="date">Date</label>
            <Select
              options={GADateOptions}
              value={gaeventselectedDateOption}
              onChange={handlegaeventdateChange}
              classNamePrefix="react-select"
            />
            <div className="col-xl-9">
              {craftedcustomDateRange && (
                <>
                  <label>From:</label>
                  <DatePicker
                    selected={craftedstartDate}
                    //   onChange={handleChangeStartCrafted}
                    className="form-control"
                  />
                  <br></br>
                  <label>To:</label>
                  <DatePicker
                    selected={craftedendDate}
                    //   onChange={handleChangeEndCrafted}
                    className="form-control"
                  />
                </>
              )}
            </div>
          </div>

          <div className="col-md-2">
            <label htmlFor="age">Event Options</label>
            <Select
              options={GAEventOptions}
              value={gaeventselectedOption}
              onChange={handlegaeventChange}
              classNamePrefix="react-select"
            />
          </div>
          {/* <div className="col-md-2">
            <label htmlFor="location">Location</label>
            <select className="form-control" id="location">
              <option value="all">All</option>
            </select>
          </div> */}

          <div className="col-md-2 mt-3 pt-1">
            <button className="btn btn-secondary">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="col-lg-12 mb-1 mb-lg-0">
          {/* <Chart
            type="line"
            options={CraftedChartOptions}
            series={CraftedChartData}
            height={600}
          /> */}

          <canvas ref={expandChartRef} className="w-100" height="700" />
        </div>
      </>
    );
  };

  const GAPageScreenExpand = () => {
    const expandChartRef = useRef(null);

    useEffect(() => {
      const chartInstance = setuppagescreenChart(
        expandChartRef,
        pagescreenlabels,
        pagescreenlinevalues,
        themeColor,
        themeColorRgb,
        bodyBg
      );
      return () => chartInstance && chartInstance.destroy();
    }, [pagescreenlabels,pagescreenlinevalues, themeColor, themeColorRgb, bodyBg]);

    return (
    <>
      <h3>GA - Page/Screen - {gapagescreenselectedOption.label}</h3>
      <div className="row">
        {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
        <h2 className="col-xl-3">
            {PageScreenTile && PageScreenTile.totalMetricValue
              ? PageScreenTile.totalMetricValue
              : 0}
          </h2>
        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={GADateOptions}
            value={gapagescreenselectedDateOption}
            onChange={handlegapagescreendateChange}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {craftedcustomDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={craftedstartDate}
                  //   onChange={handleChangeStartCrafted}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={craftedendDate}
                  //   onChange={handleChangeEndCrafted}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Page/Screen Options</label>
          <Select
            options={GAPageScreenOptions}
            value={gapagescreenselectedOption}
            onChange={handlegapagescreenChange}
            classNamePrefix="react-select"
          />
        </div>
        {/* <div className="col-md-2">
            <label htmlFor="location">Location</label>
            <select className="form-control" id="location">
              <option value="all">All</option>
            </select>
          </div> */}

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        {/* <Chart
            type="line"
            options={CraftedChartOptions}
            series={CraftedChartData}
            height={600}
          /> */}
          <canvas ref={expandChartRef} className="w-100" height="700" />
      </div>
    </>
  )};

  const GASessionExpand  = () => {
    const expandChartRef = useRef(null);

    useEffect(() => {
      const chartInstance = setupsessionChart(
        expandChartRef,
        sessionlabels,
        sessionlinevalues,
        themeColor,
        themeColorRgb,
        bodyBg
      );
      return () => chartInstance && chartInstance.destroy();
    }, [sessionlabels,sessionlinevalues, themeColor, themeColorRgb, bodyBg]);

    return (
    <>
      <h3>GA - Session - {gasessionselectedOption.label}</h3>
      <div className="row">
        {/* {data && <h2 className="col-xl-3">{data.make}</h2>} */}
        <h2 className="col-xl-3">
        {SessionTile && SessionTile.totalMetricValue
                      ? SessionTile.totalMetricValue
                      : 0}
        </h2>
        <div className="col-md-2">
          <label htmlFor="date">Date</label>
          <Select
            options={GADateOptions}
            value={gasessionselectedDateOption}
            onChange={handlegasessiondateChange}
            classNamePrefix="react-select"
          />
          <div className="col-xl-9">
            {craftedcustomDateRange && (
              <>
                <label>From:</label>
                <DatePicker
                  selected={craftedstartDate}
                  //   onChange={handleChangeStartCrafted}
                  className="form-control"
                />
                <br></br>
                <label>To:</label>
                <DatePicker
                  selected={craftedendDate}
                  //   onChange={handleChangeEndCrafted}
                  className="form-control"
                />
              </>
            )}
          </div>
        </div>

        <div className="col-md-2">
          <label htmlFor="age">Session Options</label>
          <Select
            options={GASessionOptions}
            value={gasessionselectedOption}
            onChange={handlegasessionChange}
            classNamePrefix="react-select"
          />
        </div>
        {/* <div className="col-md-2">
            <label htmlFor="location">Location</label>
            <select className="form-control" id="location">
              <option value="all">All</option>
            </select>
          </div> */}

        <div className="col-md-2 mt-3 pt-1">
          <button className="btn btn-secondary">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div className="col-lg-12 mb-1 mb-lg-0">
        {/* <Chart
            type="line"
            options={CraftedChartOptions}
            series={CraftedChartData}
            height={600}
          /> */}
          <canvas ref={expandChartRef} className="w-100" height="700" />
      </div>
    </>
  )
};


function sortDataByDateDescending(dailyData) {

  return dailyData.sort((a, b) => {
      const dateA = new Date(a.dimensionValues[0].value.split('-').reverse().join('-'));
      const dateB = new Date(b.dimensionValues[0].value.split('-').reverse().join('-'));

      return dateA - dateB; // Descending order
  });
}

  useEffect(() => {
    const UserTileInfo = async (dateoption) => {
      try {
        let startDate = gauserselectedDateOption.value;
        let endDate = "today";
        let dimension = "date";
        let metric = gauserselectedOption.value;
        // console.log(startDate, metric, "YES WE GET");
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/ga4/data/analytics/users?startDate=${startDate}&endDate=${endDate}&dimension=${dimension}&metric=${metric}`
        );
        const result = await response.json();
          // console.log(result);
         
          const sortedData = sortDataByDateDescending(result.dailyData);

        setDataUserTile({ dailyData: sortedData, totalMetricValue: result.totalMetricValue });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    UserTileInfo(gauserselectedDateOption.value);
  }, [gauserselectedOption, gauserselectedDateOption]);

  useEffect(() => {
    const EcommerceTileInfo = async (dateoption) => {
      try {
        let startDate = gaecommerceselectedDateOption.value;
        let endDate = "today";
        let dimension = "date";
        let metric = gaecommerceselectedOption.value;
        // console.log(startDate, metric, "YES WE GET");
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/ga4/data/analytics/ecommerce?startDate=${startDate}&endDate=${endDate}&dimension=${dimension}&metric=${metric}`
        );
        const result = await response.json();

        const sortedData = sortDataByDateDescending(result.dailyData);

        //   console.log(result);
        setDataEcommerceTile({ dailyData: sortedData, totalMetricValue: result.totalMetricValue });;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    EcommerceTileInfo(gaecommerceselectedDateOption.value);
  }, [gaecommerceselectedOption, gaecommerceselectedDateOption]);

  useEffect(() => {
    const RevenueTileInfo = async (dateoption) => {
      try {
        let startDate = garevenueselectedDateOption.value;
        let endDate = "today";
        let dimension = "date";
        let metric = garevenueselectedOption.value;
        // console.log(startDate, metric, "YES WE GET");
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/ga4/data/analytics/revenue?startDate=${startDate}&endDate=${endDate}&dimension=${dimension}&metric=${metric}`
        );
        const result = await response.json();
        const sortedData = sortDataByDateDescending(result.dailyData);

        // console.log(result);
        setDataRevenueTile({ dailyData: sortedData, totalMetricValue: result.totalMetricValue });;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    RevenueTileInfo(garevenueselectedDateOption.value);
  }, [garevenueselectedOption, garevenueselectedDateOption]);

  useEffect(() => {
    const EventTileInfo = async (dateoption) => {
      try {
        let startDate = gaeventselectedDateOption.value;
        let endDate = "today";
        let dimension = "date";
        let metric = gaeventselectedOption.value;
        // console.log(startDate, metric, "YES WE GET");
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/ga4/data/analytics/event?startDate=${startDate}&endDate=${endDate}&dimension=${dimension}&metric=${metric}`
        );
        const result = await response.json();

        const sortedData = sortDataByDateDescending(result.dailyData);

        // console.log(result);
        setDataEventTile({ dailyData: sortedData, totalMetricValue: result.totalMetricValue });;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    EventTileInfo(gaeventselectedDateOption.value);
  }, [gaeventselectedOption, gaeventselectedDateOption]);

  useEffect(() => {
    const PageScreenTileInfo = async (dateoption) => {
      try {
        let startDate = gapagescreenselectedDateOption.value;
        let endDate = "today";
        let dimension = "date";
        let metric = gapagescreenselectedOption.value;
        // console.log(startDate, metric, "YES WE GET");
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/ga4/data/analytics/pagescreen?startDate=${startDate}&endDate=${endDate}&dimension=${dimension}&metric=${metric}`
        );
        const result = await response.json();
        const sortedData = sortDataByDateDescending(result.dailyData);
        // console.log(result);
        setDataPageScreenTile({ dailyData: sortedData, totalMetricValue: result.totalMetricValue });;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    PageScreenTileInfo(gapagescreenselectedDateOption.value);
  }, [gapagescreenselectedOption, gapagescreenselectedDateOption]);

  useEffect(() => {
    const SessionTileInfo = async (dateoption) => {
      try {
        let startDate = gasessionselectedDateOption.value;
        let endDate = "today";
        let dimension = "date";
        let metric = gasessionselectedOption.value;
        // console.log(startDate, metric, "YES WE GET");
        const response = await fetch(
          `${process.env.REACT_APP_API_ADDRESS}/ga4/data/analytics/sessions?startDate=${startDate}&endDate=${endDate}&dimension=${dimension}&metric=${metric}`
        );
        const result = await response.json();
        const sortedData = sortDataByDateDescending(result.dailyData);
        //   console.log(result);
        setDataSessionTile({ dailyData: sortedData, totalMetricValue: result.totalMetricValue });;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    SessionTileInfo(gasessionselectedDateOption.value);
  }, [gasessionselectedOption, gasessionselectedDateOption]);

  return (
    <>
        <div className="row mb-3">
        <div className="col-xl-6 col-lg-6">
          <h6>
            <span style={{ color: "#3cd2a5" }}>PAGES</span> / GOOGLE ANALYTICS1
          </h6>
          <h2>GOOGLE ANALYTICS HOME</h2>
        </div>
      </div>
      <div className="row">
        {/* User */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h4 className="flex-grow-1">User</h4>
                <ModalComponent cardBodyContent={<GAUserExpand />} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h4 className="mb-0">
                    {UserTile && UserTile.totalMetricValue
                      ? UserTile.totalMetricValue
                      : 0}
                    {/* 13,000 */}
                  </h4>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {/* {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0} */}
                  </h5>
                  <p className="mb-0">{gauserselectedOption.label}</p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                {/* <Chart
                  type="line"
                  height="100%"
                  options={CraftedChartOptions}
                  series={CraftedChartData}
                /> */}
                <canvas ref={mainChartRef} />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {/* {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0} */}
                {/* % more active user than last week */}
              </div>
            </CardBody>
          </Card>
        </div>

        {/* Ecommerce */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h4 className="flex-grow-1">Ecommerce</h4>
                <ModalComponent cardBodyContent={<GAEcommerceExpand />} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h4 className="mb-0">
                    {EcommerceTile && EcommerceTile.totalMetricValue
                      ? EcommerceTile.totalMetricValue
                      : 0}
                  </h4>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {/* {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0} */}
                  </h5>
                  <p className="mb-0">{gaecommerceselectedOption.label}</p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                {/* <Chart
                  type="line"
                  height="100%"
                  options={CraftedChartOptions}
                  series={CraftedChartData}
                /> */}
                <canvas ref={ecommerceChartRef} />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {/* {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0} */}
                {/* % more active user than last week */}
              </div>
            </CardBody>
          </Card>
        </div>

        {/* Revenue */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h4 className="flex-grow-1">Revenue</h4>
                <ModalComponent cardBodyContent={<GARevenueExpand />} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h4 className="mb-0">
                    {RevenueTile && RevenueTile.totalMetricValue
                      ? RevenueTile.totalMetricValue
                      : 0}
                  </h4>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {/* {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0} */}
                  </h5>
                  <p className="mb-0">{garevenueselectedOption.label}</p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                {/* <Chart
                  type="line"
                  height="100%"
                  options={CraftedChartOptions}
                  series={CraftedChartData}
                /> */}
                <canvas ref={revenueChartRef} />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {/* {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0} */}
                {/* % more active user than last week */}
              </div>
            </CardBody>
          </Card>
        </div>

        {/* Event */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h4 className="flex-grow-1">Event</h4>
                <ModalComponent cardBodyContent={<GAEventExpand />} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h4 className="mb-0">
                    {EventTile && EventTile.totalMetricValue
                      ? EventTile.totalMetricValue
                      : 0}
                  </h4>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {/* {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0} */}
                  </h5>
                  <p className="mb-0">{gaeventselectedOption.label}</p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                {/* <Chart
                  type="line"
                  height="100%"
                  options={CraftedChartOptions}
                  series={CraftedChartData}
                /> */}
                <canvas ref={eventChartRef} />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {/* {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0} */}
                {/* % more active user than last week */}
              </div>
            </CardBody>
          </Card>
        </div>

        {/* Page/Screen */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h4 className="flex-grow-1">Page/Screen</h4>
                <ModalComponent cardBodyContent={<GAPageScreenExpand />} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h4 className="mb-0">
                  {PageScreenTile && PageScreenTile.totalMetricValue
                      ? PageScreenTile.totalMetricValue
                      : 0}
                  </h4>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {/* {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0} */}
                  </h5>
                  <p className="mb-0">{gapagescreenselectedOption.label}</p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                {/* <Chart
                  type="line"
                  height="100%"
                  options={CraftedChartOptions}
                  series={CraftedChartData}
                /> */}
                <canvas ref={pagescreenChartRef} />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {/* {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0} */}
                {/* % more active user than last week */}
              </div>
            </CardBody>
          </Card>
        </div>

        {/* Session */}
        <div className="col-xl-6 col-lg-12">
          <Card className="mb-3">
            <CardBody>
              <div className="d-flex fw-bold small mb-3">
                <h4 className="flex-grow-1">Session</h4>
                <ModalComponent cardBodyContent={<GASessionExpand />} />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-6 text-right mb-1">
                  <h4 className="mb-0">
                  {SessionTile && SessionTile.totalMetricValue
                      ? SessionTile.totalMetricValue
                      : 0}
                  </h4>
                </div>
                <div className="col-6 text-end mt-2">
                  <h5 className="mb-0" style={{ color: "#3CD25D" }}>
                    {/* {data && data.activeuserlastWeekUser
                      ? data.activeuserlastWeekUser
                      : 0} */}
                  </h5>
                  <p className="mb-0">{gasessionselectedOption.label}</p>
                </div>
              </div>
              <div className="ratio ratio-21x9 mb-4 ">
                {/* <Chart
                  type="line"
                  height="100%"
                  options={CraftedChartOptions}
                  series={CraftedChartData}
                /> */}
                <canvas ref={sessionChartRef} />
              </div>
              <div className="mt-4 pb-4">
                <i className="fas fa-angle-up"></i>
                {/* {data && data.activeuserlastWeekUser
                  ? data.activeuserlastWeekUser
                  : 0} */}
                {/* % more active user than last week */}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Gahomemain;
